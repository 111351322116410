<template>
  <div id="appHome">
    <div class="topCon">
      <full-page :options="options" ref="fullpage" class="flg">
        <div class="section">
          <div class="bannerWrap" :style="'height:' + heights + ';'">
            <!-- @change="handleCarousel" -->
            <div class="prev">
              <div class="prevItem" @click="arrowClick('prev')">
                <img class="prevItem1" src="../assets/newimgs/z@2x.png" />
                <img class="prevItem2" src="../assets/newimgs/zz@2x.png" />
              </div>
              <div class="prevItems" @click="arrowClick('next')">
                <img class="prevItem3" src="../assets/newimgs/y@2x.png" />
                <img class="prevItem4" src="../assets/newimgs/yy@2x.png" />
              </div>
            </div>
            <el-carousel :height="heights" width="100vw" :autoplay="true" arrow="never" ref="cardShow">
              <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <div class="video" style="width: 100%; height: 100%;transition: all 0.5s;" @click="handerBanner(index)">
                  <img :src="item.url" alt style="width: 100%; height: 100%;transition: all 0.5s;object-fit: cover;" />
                  <!-- filter: brightness(80%); -->
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="title">
              <div class="immm">
                <img src="../assets/newimgs/HANGZHOU@2x.png" />
              </div>
              <div class="qy">
                <span>全</span>
                <span>域</span>
                <span>数</span>
                <span>字</span>
                <span>文</span>
                <span>旅</span>
              </div>
              <div class="tit1">
                <span>浙江骥宇天哲信息科技有限公司</span>
                <span class="tit">|</span>
                <span>一站式数字化改革落地服务</span>
              </div>
              <div class="tit2">
                <span>|</span>
                <div class="tittwo" v-for="(it, s) in toptitList" :key="s">
                  <span>{{ it.name }}</span>
                  <span>|</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="contOne">
            <div class="gyjy">
              <div class="gytit o-p">骥宇天哲</div>
              <div class="about o-p">ABOUT US</div>
              <span class="top_xian"></span>
            </div>
            <div class="gsjj">
              <div class="jjleft" id="jjleft">
                <div class="xian_left"></div>
                <!-- <p v-if="!isMScreen">{{ jieshao.newsTitle }}</p> -->
                <div class="jjleft_one">
                  <p id="p1" class="o-p">浙江骥宇天哲信息科技有限公司</p>
                  <!-- <p id="p2" class="o-p">信息科技有限公司</p> -->
                  <span class="xian"></span>
                  <div class="htm o-p" id="htm" v-html="jieshao.content"></div>
                  <div class="QHcolor2" @click="gent('/AboutView', 0)">
                    <!-- <span class="gd">了解详情</span> -->
                    了解详情
                  </div>
                </div>
                <br />
                <br />
              </div>
              <div class="bjj o-p" id="jjright" v-show="showdiv">
                <div :class="['oneone', inx == an ? 'oneone2' : '']" v-for="(item, an) in animate" :key="an"
                  @mouseover="addActive(an)" @mouseout="removeActive(an)">
                  <div id="root">
                    <span id="numValue" data-time="1500" v-if="item.tite == '行业经验'">0</span>
                    <span id="numValue1" data-time="1500" v-if="item.tite == '研发团队'">0</span>
                    <span id="numValue2" data-time="1500" v-if="item.tite == '公司产品'">0</span>
                    <span id="numValue3" data-time="1500" v-if="item.tite == '行业项目'">0</span>
                    <!-- <animate-number from="0" :to=item.to :duration="time" easing="easeOutQuad"
                    :formatter="formatter"></animate-number>-->
                    <span>+</span>
                  </div>
                  <p>{{ item.tite }}</p>
                </div>
              </div>
              <div id="jjright" v-show="showteam">
                <div class="aboutsmall" v-for="(z, m) in animate" :key="m"
                  :style="{ 'background': 'url(' + z.img + ') center no-repeat' }">
                  <p>{{ z.tite }}</p>
                  <div id="root2">
                    <animate-number from="0" :to="z.to" :duration="time" easing="easeOutQuad"
                      :formatter="formatter"></animate-number>
                    <span>+</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section fp-section">
          <div class="contFive">
            <div class="gyjy">
              <div class="gytit o-p">新闻动态</div>
              <div class="about o-p">NEWS INFORMATION</div>
              <span class="top_xian"></span>
            </div>
            <div class="news" v-show="showdiv">
              <div class="newslist" v-if="index < 3" v-for="(v, index) in newsList" :key="index" @click="news(v)">
                <span class="newstime">{{ v.releaseTime }}</span>
                <div class="date-img">
                  <img :src="v.imagesUrls" alt class="imgs" />
                </div>
                <div class="nn">
                  <div class="titlediv">
                    {{ v.newsTitle }}
                    <span class="newstime2" v-show="showteam">
                      {{
                        v.releaseTime
                      }}
                    </span>
                  </div>

                  <div class="con-txt">{{ v.digest }}</div>
                </div>
                <div class="ljxq2">
                  了解详情
                  <img src="../assets/imgs/jiantou.png" alt />
                </div>
              </div>
            </div>
            <div class="phonenews" v-show="showteam">
              <div class="listone">
                <div class="one_img">
                  <img :src="newsList[0].imagesUrls" />
                </div>
                <div class="one_nn" @click="news(newsList[0])">
                  <p>{{ newsList[0].newsTitle }}</p>
                  <p>{{ newsList[0].releaseTime }}</p>
                  <div class="one_txt">{{ newsList[0].digest }}</div>
                </div>
              </div>
              <div class="phonelist" v-for="(x, c) in newsList" :key="c" v-if="c != 0 && c < 3" @click="news(x)">
                <div class="listtitle">
                  <p>{{ x.newsTitle }}</p>
                  <p>{{ x.releaseTime }}</p>
                </div>
                <div class="listlo">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
            <div class="moreCont viewTime">
              <div class="QHcolor2" @click="gent('/NewsView', 0)">更多方案</div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="contTwo">
            <div class="gyjy">
              <div class="gytit o-p">我们的优势</div>
              <div class="about o-p">OUR ADVANTAGES</div>
              <span class="top_xian"></span>
            </div>
            <div class="youshi">
              <div class="wmdys">
                <div class="ysbox" v-for="(v, c) in ysList" :key="c">
                  <div class="imgss">
                    <img :src="v.img" />
                  </div>
                  <div class="ysname">{{ v.name }}</div>
                  <div class="bianhao">
                    <span>{{ v.value }}</span>
                    <br />
                    <br />
                    <span>{{ v.value1 }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="contThree">
            <div class="gyjy">
              <div class="gytit o-p">解决方案</div>
              <div class="about o-p">TOTAL SOLUTION</div>
              <span class="top_xian"></span>
            </div>
            <div class="total">
              <!-- :style="'background: ' + 'url(' + item.bg + ');' + 'background-size:100% 100%;'" -->
              <div class="solon" v-for="(item, x) in totallist" :key="x" v-show="showdiv">
                <img :src="item.bg" />
                <div class="return">
                  <p>{{ item.title }}</p>
                  <p>{{ item.englishtitle }}</p>
                  <!-- :data="item.keyword" :class-option="defaultOption" -->
                  <div class="seamless-warp">
                    <div class="key">
                      <div class="keyname" v-for="(it, n) in item.keyword" :key="n">{{ it.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="qybt" v-for="(item, x) in jjfa" :key="x" v-show="showdiv"
                :style="'background: ' + 'url(' + item.bg + ');' + 'background-size:100% 100%;'">
                <div class="bboo">
                  <img :src="item.img" />
                </div>
                <span class="xian"></span>
                <div class="totalbox">
                  <span>{{ item.title }}</span>
                  <br />
                  <span>{{ item.english }}</span>
                </div>
                <div class="block" @click="gent('/totalSolution', x)">
                  <div class="ttle">
                    <span>{{ item.title }}</span>
                    <br />
                    <span>{{ item.english }}</span>
                    <br />
                    <span class="xian"></span>
                    <div class="sp" v-html="item.value"></div>
                  </div>
                </div>
              </div>-->
              <swiper :options="swiperOptionFour" ref="mySwiperFour" class="FourSwiper" v-show="showteam">
                <swiper-slide class="solon" v-for="(item, x) in totallist" :key="x">
                  <img :src="item.bg" :data-item="x" />
                  <!-- <div class="return">
                    <p>{{ item.title }}</p>
                    <p>{{ item.englishtitle }}</p>
                    <div class="seamless-warp">
                      <div class="key">
                        <div class="keyname" v-for="(it, n) in item.keyword" :key="n">
                          {{ it.name }}
                        </div>
                      </div>
                    </div>
                  </div>-->
                </swiper-slide>
              </swiper>
              <!-- <swiper :options="swiperOptionFour" ref="mySwiperFour" class="FourSwiper" v-show="showteam">
                <swiper-slide class="case" v-for="(item, x) in jjfa" :key="x"
                  :style="'background: ' + 'url(' + item.bg + ');' + 'background-size:100% 100%;'">
                  <div class="bboo">
                    <img :src="item.img" />
                  </div>
                  <span class="xian"></span>
                  <div class="totalbox">
                    <span>{{ item.title }}</span>
                    <br />
                    <span>{{ item.english }}</span>
                  </div>
                  <div class="block" @click="gent('/totalSolution', x)">
                    <div class="ttle">
                      <span>{{ item.title }}</span>
                      <br />
                      <span>{{ item.english }}</span>
                      <br />
                      <span class="xian"></span>
                      <div class="sp" v-html="item.value"></div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>-->
              <div class="swiper-pagination" slot="pagination"></div>
            </div>
            <div class="moreCont viewTime">
              <div class="QHcolor2" @click="gent('/totalSolution', 0)">更多方案</div>
            </div>
          </div>
        </div>
        <div class="section fp-section">
          <div class="contFour">
            <div class="gyjy">
              <div class="gytit o-p">客户案例</div>
              <div class="about o-p">CUSTOMER STORIES</div>
              <span class="top_xian"></span>
            </div>

            <div class="neimu">
              <!-- <div class="neimuleft">
                <div class="anniu">
                  <div class="anniuone" @click="anniuone(1)">
                    <img v-show="anniushow1" src="../assets/imgs/anniu/2.png">
                    <img v-show="anniu1" src="../assets/imgs/anniu/1.png">
                    <span>智慧文旅</span>
                  </div>
                  <div class="anniuone" @click="anniuone(2)">
                    <img v-show="anniushow2" src="../assets/imgs/anniu/2.png">
                    <img v-show="anniu2" src="../assets/imgs/anniu/1.png"><span>智慧景区</span>
                  </div>
                  <div class="anniuone" @click="anniuone(3)">
                    <img v-show="anniushow3" src="../assets/imgs/anniu/2.png">
                    <img v-show="anniu3" src="../assets/imgs/anniu/1.png"><span>数字乡村</span>
                  </div>
                </div>
                <div class="tu">
                  <img :src=imgsimgs>
                  <span>{{xmmc}}</span>
                </div>
                <div class="moreCont viewTime">
                  <div class="QHcolor2" @click="gent('/kehual', 0)">更多案例</div>
                </div>
              </div> -->
              <div class="kuangleft">

                <div class="kuang1" @click="jumpto(1)">
                  <span>智慧景区</span>
                  <div class="nn1">
                    <vue-seamless-scroll :data="jingqu" :class-option="defaultOption">
                      <ul>
                        <li v-for="(v, index) in jingqu" :key="index">{{ v.name }}</li>
                      </ul>
                    </vue-seamless-scroll>

                  </div>
                </div>
                <div class="kuang2"  @click="jumpto(2)">
                  <span>数字乡村</span>
                  <div class="nn2" v-if="xangc.length>0">
                    <vue-seamless-scroll :data="xangc" :class-option="defaultOption">
                      <ul>
                        <li v-for="(v, index) in xangc" :key="index">{{ v.name }}</li>
                      </ul>
                    </vue-seamless-scroll>
                  </div>
                </div>
              </div>
              <div id="mapBox"></div><!-- 地图 -->
              <div class="kuang3" @click="jumpto(0)">
                <span >智慧文旅</span>
                <div class="nn3">
                  <vue-seamless-scroll :data="lvyou" :class-option="defaultOption">
                    <ul>
                      <li v-for="(v, index) in lvyou" :key="index">{{ v.name }}</li>
                    </ul>
                  </vue-seamless-scroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section fp-section" id="sec7">
          <div class="footerCon">
            <footerBox></footerBox>
          </div>
        </div>
      </full-page>
      <ItemHeader :ind1="0"></ItemHeader>
      <div class="icon animate__animated animate__bounceInRight">
        <div class="iconbg">
          <div class="dianhua">
            <span>0571-83691210</span>
          </div>
          <div class="imgicon">
            <img src="../assets/newimgs/dh@2x.png" />
          </div>
        </div>
        <div class="iconbg" @click="jumpTop1">
          <div class="imgicon">
            <img src="../assets/newimgs/zd@2x.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCountUp from "vue-countupjs";
import _ from "lodash";
import ItemHeader from "@/components/ItemHeader.vue";
import footerBox from "@/components/footerBox.vue";
import { getNotice, getview } from "../api/index.js";
var echarts = require("echarts");
require("echarts/map/js/china");
// require("echarts/map/js/province/zhejiang.js");
import utils from "@/api/utils";
import colorBg from "@/assets/guang.png";
import "swiper/dist/css/swiper.css";
import vueSeamlessScroll from "vue-seamless-scroll";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import AMapLoader from '@amap/amap-jsapi-loader';
// import * as echarts from 'echarts';
import 'echarts-gl';
import allinfo from "@/assets/js/xiangmuData.js"; // 地图上项目数据
import LabelsData from "@/assets/js/shuju.js"; // 地图上项目数据
let map, marKer, infoWindow;
export default {
  components: {
    swiper,
    swiperSlide,
    footerBox,
    ItemHeader,
    VueCountUp,
    vueSeamlessScroll
  },
  created() {
    window.vm = this;
    // 地图上项目数据
    // this.dataList = allinfo.dataList;
    // let that = this;
    // window._AMapSecurityConfig = {
    //   securityJsCode: '4af875c4801a2b351f3e01cdb23c0edc'
    // };
    // AMapLoader.load({
    //   key: '6f5f633ccf817493411b68e2b5f5558c', // 申请好的Web端开发者Key，首次调用 load 时必填
    //   version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    //   plugins: ['Map3D', 'AMap.DistrictLayer', 'AMap.Scale', 'AMap.ToolBar', 'AMap.DistrictSearch'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    // })
    //   .then(AMap => {
    //     that.gaode();
    //     console.log(111)
    //   })
    //   .catch(e => { });
  },
  computed: {
    defaultOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },

  data() {
    return {
      dataList: [],
      idd: 1,
      xmmc: '浙江文旅',
      width: 500,
      height: 220,
      inx: 0,
      lvyou: [],
      xangc: [],
      jingqu: [],
      anlilist: [],
      anniushow1: false,
      anniushow2: true,
      anniushow3: true,
      anniu1: true,
      anniu2: false,
      anniu3: false,
      //map3D 配置项开始
      autoRotate: false, //是否开启视角绕物体的自动旋转查看。
      autoRotateDirection: "cw", //物体自传的方向。默认是 'cw' 也就是从上往下看是顺时针方向，也可以取 'ccw'，既从上往下看为逆时针方向。
      autoRotateSpeed: 20, //旋转速度
      autoRotateAfterStill: 3, //在鼠标静止操作后恢复自动旋转的时间间隔。在开启 autoRotate 后有效。
      damping: 0.8, //鼠标进行旋转，缩放等操作时的迟滞因子，在大于 0 的时候鼠标在停止操作后，视角仍会因为一定的惯性继续运动（旋转和缩放）。
      rotateSensitivity: 5, //旋转操作的灵敏度，值越大越灵敏。支持使用数组分别设置横向和纵向的旋转灵敏度。0不能旋转
      distance: 92, //地图大小   值越大-地图越小，值越小-地图越大
      alpha: 45, //垂直翻转角度
      beta: 0, //视角绕 y 轴，即左右旋转的角度。
      zoomSensitivity: 3, //缩放操作的灵敏度，值越大越灵敏。0表示不能缩放
      center: [0, -10, 0], //[ 18, 10, 0 ]视角中心点，旋转也会围绕这个中心点旋转，默认为[0,0,0]。
      //map3D 配置项结束
      //lines3D 轨迹线数据
      newsList: [
        {
          time: "2023.1.21",
          // imgs: require(''),
          title: "新闻标题新闻标题",
          content:
            "本招标项目仅供正式会员查看，您的权限不能浏览详细信息,请联系办理会员入网事宜，成为正式会员后可下载详细的招标公告、报名表格、项目附件和招标文件等。"
        },
        {
          time: "2023.1.21",
          // imgs: require(''),
          title: "新闻标题新闻标题",
          content:
            "本招标项目仅供正式会员查看，您的权限不能浏览详细信息,请联系办理会员入网事宜，成为正式会员后可下载详细的招标公告、报名表格、项目附件和招标文件等。"
        },
        {
          time: "2023.1.21",
          // imgs: require(''),
          title: "新闻标题新闻标题",
          content:
            "本招标项目仅供正式会员查看，您的权限不能浏览详细信息,请联系办理会员入网事宜，成为正式会员后可下载详细的招标公告、报名表格、项目附件和招标文件等。"
        }
      ],
      // style:{},
      // heights: '1080px',
      scale: this.getScale(),
      activeIndex: 0,
      heights: "100vh",
      showdiv: true,
      showteam: false,
      screenWidth: null,
      animate: [
        {
          to: "14",
          tite: "行业经验",
          img: require("@/assets/imgs/guanyu/1.png")
        },
        {
          to: "65",
          tite: "研发团队",
          img: require("@/assets/imgs/guanyu/2.png")
        },
        {
          to: "95",
          tite: "公司产品",
          img: require("@/assets/imgs/guanyu/3.png")
        },
        {
          to: "300",
          tite: "行业项目",
          img: require("@/assets/imgs/guanyu/4.png")
        }
      ],
      options: {
        // 为每个section设置背景色
        //sectionsColor: ["#f00","#0f0","#00f"],
        //用来控制slide幻灯片的箭头，设置为false，两侧的箭头会消失
        //controlArrows: false,
        //每一页幻灯片的内容是否垂直居中
        verticalCentered: true,
        //字体是否随着窗口缩放而缩放
        // resize: true,
        //页面滚动速度
        //scrollingSpeed: 700,
        //定义锚链接，用户可以快速打开定位到某一页面；不需要加"#"，不要和页面中任意的id和name相同
        //anchors: ["page1","page2","page3"],
        //是否锁定锚链接
        //lockAnchors: true,
        //定义section页面的滚动方式，需要引入jquery.easings插件
        //easing:,
        //是否使用css3 transform来实现滚动效果
        //css3: true,
        //滚动到最顶部后是否连续滚动到底部
        // loopTop: true,
        //滚动到最底部后是否连续滚动到顶部
        //loopBottom: true,
        //横向slide幻灯片是否循环滚动
        //loopHorizontal: false,
        //是否循环滚动，不会出现跳动，效果很平滑
        //continuousVertical: true,
        //是否使用插件滚动方式，设为false后，会出现浏览器自带的滚动条，将不会按页滚动
        //autoScrolling: false,
        //是否包含滚动条，设为true，则浏览器自带的滚动条会出现，页面还是按页滚动，但是浏览器滚动条默认行为也有效
        //scrollBar: true,
        //设置每个section顶部的padding，当我们要设置一个固定在顶部的菜单、导航、元素等时使用
        //paddingTop: "100px",
        //设置每个section底部的padding，当我们要设置一个固定在底部的菜单、导航、元素等时使用
        //paddingBottom: "100px",
        //固定的元素，为jquery选择器；可用于顶部导航等
        //fixedElements: ".nav",
        //是否可以使用键盘方向键导航
        //keyboardScrolling: false,
        //在移动设置中页面敏感性，最大为100，越大越难滑动
        //touchSensitivity: 5,
        //设为false，则通过锚链接定位到某个页面不再有动画效果
        //animateAnchor: false,
        //是否记录历史，可以通过浏览器的前进后退来导航
        //recordHistory: true,
        //绑定菜单，设定相关属性和anchors的值对应后，菜单可以控制幻灯片滚动
        //menu: '.nav',
        //是否显示导航，设为true会显示小圆点作为导航
        navigation: true,
        navigationColor: "#fff",
        //导航小圆点的位置，可以设置为left或者right
        navigationPosition: "left",
        //鼠标移动到小圆点上时显示出的提示信息
        //navigationTooltips: ["第一页","第二页","第三页"],
        //是否显示当前页面小圆点导航的提示信息，不需要鼠标移上
        //showActiveTooltip: true,
        //是否显示横向幻灯片的导航
        //slidesNavigation: true,
        //横向幻灯片导航的位置，可以为top或者bottom
        //slidesNavPosition: bottom,
        //内容超过满屏时是否显示滚动条，需要jquery.slimscroll插件
        //scrollOverflow: true,
        //section选择器
        //sectionSelector: ".section",
        //slide选择器
        //slideSelector: ".slide"
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        afterLoad: this.afterLoad,
        // scrollOverflow: true,
        //是否显示导航，默认为false
        navigation: true,
        scrollBar: false,
        menu: "#menu"
        // navigation: true,
        // anchors: ['page1', 'page2', 'page3'],
        // sectionsColor: ['#41b883', '#ff5f45', '#0798ec', '#fec401', '#1bcee6', '#ee1a59', '#2c3e4f', '#ba5be9', '#b4b8ab']
      },
      bannerList: [
        // {
        //   type: 'video',
        //   url: require('../assets/media/video1.mp4'),
        // },
        {
          type: "img",
          url: require("@/assets/222(1).jpg")
        },
        {
          type: "img",
          url: require("@/assets/t.jpg")
        },

        {
          type: "img",
          url: require("@/assets/bn2.jpg")
        }
      ],
      toptitList: [
        { name: "智慧城市" },
        { name: "数字文旅" },
        { name: "全域旅游" },
        { name: "智慧景区" },
        { name: "数字乡村" },
        { name: "智慧城镇" },
        { name: "咨询规划" }
      ],
      toplist: [
        {
          title: "13+",
          value: "拥有超过13年的文化和旅游行业信息化系统技术研发和项目运营经验",
          mtext: "行业经验"
        },
        {
          title: "65+",
          value:
            "65位技术研发创新团队，多学科交叉，专业配置，并配置多位技术学科类型带头人",
          mtext: "创新团队"
        },
        {
          title: "68+",
          value:
            "自主研发的软件产品95个，其中68个已取得专利、软件产品证书和系统软件著作权",
          mtext: "产品专利"
        },
        {
          title: "153+",
          value:
            "已服务153个文旅局、度假区、旅投集团、景区等政府事业单位，完成项目超300个",
          mtext: "行业项目"
        }
      ],
      ysList: [
        {
          img: require("@/assets/newimgs/zy@2x.png"),
          name: "专业",
          value: "超过十四年的专业经验",
          value1: "帮助客户少走弯路"
        },
        {
          img: require("@/assets/newimgs/td@2x.png"),
          name: "团队",
          value: "专注的开发运维团队",
          value1: "帮助客户建立标准"
        },
        {
          img: require("@/assets/newimgs/dly@2x.png"),
          name: "多领域",
          value: "多领域的跨界合作者",
          value1: "帮助客户建立标准"
        },
        {
          img: require("@/assets/newimgs/sd@2x.png"),
          name: "深度",
          value: "智慧大数据深度应用",
          value1: "帮助客户发现真相"
        },
        {
          img: require("@/assets/newimgs/qy@2x.png"),
          name: "前沿",
          value: "前沿系统性解决方案",
          value1: "帮助客户解决问题"
        }
        // {
        //   img: require("@/assets/imgs/icon/29@2x.png"),
        //   name:'专业',
        //   value: "5A景区和国旅规划与创建提升",
        //   value1: "一站式帮客户提供咨询"
        // }
      ],
      jjfa: [
        {
          bg: require("../assets/newimgs/c1@2x.png"),
          img: require("../assets/newimgs/qyly@2x.png"),
          title: "数字文旅",
          english: "GLOBAL TOURISM",
          value:
            "通过物联网、云计算、大数据、空间地理信息集成等智能计算技术的应用，使得城市管理、教育、医疗、房地产、交通运输、公用事业和公众安全等城市组成的关键基础设施组件和服务更互联、高效和智能"
        },
        {
          bg: require("../assets/newimgs/c2@2x.png"),
          img: require("../assets/newimgs/zhcs@2x.png"),
          title: "智慧城市",
          english: "SMART CITY",
          value:
            "通过物联网、云计算、大数据、空间地理信息集成等智能计算技术的应用，使得城市管理、教育、医疗、房地产、交通运输、公用事业和公众安全等城市组成的关键基础设施组件和服务更互联、高效和智能"
        },
        {
          bg: require("../assets/newimgs/c3@2x.png"),
          img: require("../assets/newimgs/zhjq@2x.png"),
          title: "智慧景区",
          english: "SCENIC SPOT",
          value:
            "通过物联网、云计算、大数据、空间地理信息集成等智能计算技术的应用，使得城市管理、教育、医疗、房地产、交通运输、公用事业和公众安全等城市组成的关键基础设施组件和服务更互联、高效和智能"
        },
        {
          bg: require("../assets/newimgs/c4@2x.png"),
          img: require("../assets/newimgs/szxc@2x.png"),
          title: "数字乡村",
          english: "COUNTRYSIDE",
          value:
            "通过物联网、云计算、大数据、空间地理信息集成等智能计算技术的应用，使得城市管理、教育、医疗、房地产、交通运输、公用事业和公众安全等城市组成的关键基础设施组件和服务更互联、高效和智能"
        }
      ],
      totallist: [
        {
          bg: require("@/assets/slices/4.png"),
          title: "文旅数据驾驶舱",
          englishtitle: "JIASHICANG",
          keyword: [
            { name: "文旅数据驾驶仓" },
            { name: "文旅基础数据仓" },
            { name: "文旅采集管理系统" },
            { name: "文旅资源管理系统" },
            { name: "数据共享交换系统" },
            { name: "游客来源地分析" },
            { name: "产业运行数据分析" },
            { name: "文旅数据中台" },
            { name: "运营决策分析系统" },
            { name: "游客精准数据分析" },
            { name: "应用支撑平台" },
            { name: "文旅基础数据" },
            { name: "从业人员数据" },
            { name: "涉旅部门数据" },
            { name: "数据抓取清洗" },
            { name: "资源目录元数据管理" },
            { name: "数据安全管理" }
          ]
        },
        {
          bg: require("@/assets/slices/6.png"),
          title: "文旅智慧服务体系",
          englishtitle: "ZHIHUIFUWU",
          keyword: [
            { name: "一机游 / 一码畅游" },
            { name: "活动预约系统" },
            { name: "游客中心体验系统" },
            { name: "手绘导游导览系统" },
            { name: "全域手绘地图" },
            { name: "VR全景视频系统" },
            { name: "AR虚拟体验系统" },
            { name: "AI智能客服系统" },
            { name: "云视频微直播" },
            { name: "文旅导览机器人" },
            { name: "二维码应用系统" },
            { name: "咨询与投诉系统" },
            { name: "智能行程定制系统" },
            { name: "导游和司机预约系统" },
            { name: "游客互动触摸屏系统" },
            { name: "露营应用系统" }
          ]
        },
        {
          bg: require("@/assets/slices/7.png"),
          title: "文旅智慧监管体系",
          englishtitle: "ZHIHUIJIANKONG",
          keyword: [
            { name: "文旅舆情监测系统" },
            { name: "应急指挥调度系统" },
            { name: "游客预测预警系统" },
            { name: "信息统一发布系统" },
            { name: "旅游团队招徕奖励系统" },
            { name: "导游管理系统" },
            { name: "项目工程管理系统" },
            { name: "旅游新业态审批系统" },
            { name: "旅行社服务管理系统" },
            { name: "咨询投诉监管系统" },
            { name: "文旅执法管理系统" },
            { name: "文化遗产保护监管系统" },
            { name: "文旅安全生产监管系统" },
            { name: "文物工程档案管理" },
            { name: "文旅移动巡查系统" },
            { name: "文物修缮管理系统" }
          ]
        },
        {
          bg: require("@/assets/slices/5.png"),
          title: "文旅全域营销体系",
          englishtitle: "QUANYUYINGXIAO",
          keyword: [
            { name: "文旅业态预订入口" },
            { name: "电子商务系统" },
            { name: "餐饮预约管理系统" },
            { name: "住宿管理系统" },
            { name: "旅行社管理系统" },
            { name: "分销商管理系统" },
            { name: "电子票务系统" },
            { name: "商家核销管理系统" },
            { name: "服务评价系统" },
            { name: "智慧民宿PMS系统" },
            { name: "进销存管理系统" },
            { name: "仓库管理系统" },
            { name: "二销运营管理系统" },
            { name: "营销活动管理系统" },
            { name: "OTA分销系统" },
            { name: "短视频分销系统" },
            { name: "全民分销系统" }
          ]
        },
        {
          bg: require("@/assets/slices/1.png"),
          title: "智慧博物馆文化馆",
          englishtitle: "BOWU/WENHUA",
          keyword: [
            { name: "数字官方网站" },
            { name: "智能电子票务系统" },
            { name: "小程序/微信系统" },
            { name: "文化活动预约系统" },
            { name: "智慧语音导览系统" },
            { name: "自助导览系统" },
            { name: "AR导游系统" },
            { name: "VR虚拟体验系统" },
            { name: "会员管理系统" },
            { name: "虚拟博物馆平台" },
            { name: "数字资源管理系统" },
            { name: "线上博物馆展览系统" },
            { name: "NFT数字纪念品平台" },
            { name: "3D藏品管理系统" },
            { name: "球幕全息3D展示系统" },
            { name: "文创商城系统" },
            { name: "志愿者管理系统" },
            { name: "多媒体互动沉浸体验" },
            { name: "智慧博物馆管理平台" },
            { name: "安防管理系统" },
            { name: "数据可视化系统" }
          ]
        },
        {
          bg: require("@/assets/slices/3.png"),
          title: "数字乡村",
          englishtitle: "SHUZIXIANGCUN",
          keyword: [
            { name: "数据乡村驾驶仓" },
            { name: "商家诚信信用系统" },
            { name: "手绘导游导览系统" },
            { name: "云上漫村小程序" },
            { name: "游客中心户外大屏" },
            { name: "乡村旅游营销系统" },
            { name: "二维码展示系统" },
            { name: "在线点评系统" },
            { name: "咨询与投诉系统" },
            { name: "全媒体统一发布" },
            { name: "智慧厕所" },
            { name: "VR全景虚拟游" },
            { name: "乡村美景直播" },
            { name: "采摘门票预订系统" },
            { name: "民宿住宿预订系统" },
            { name: "餐饮预约点餐系统" },
            { name: "农文商品预订系统" },
            { name: "智慧票务检票系统" },
            { name: "统一收银结算系统" },
            { name: "客流车流监管" },
            { name: "智慧农业系统" },
            { name: "智慧养殖系统" },
            { name: "平安乡村系统" },
            { name: "植物病虫害防治" },
            { name: "旅游业态管理系统" },
            { name: "综合管控平台" },
            { name: "在线预订支付系统" },
            { name: "乡村旅游接待系统" },
            { name: "民宿管理系统" },
            { name: "5G直播仓" },
            { name: "客流量监控系统" },
            { name: "停车场管理系统" },
            { name: "车辆卡口管理系统" },
            { name: "云广播管理系统" },
            { name: "在线分享分销系统" },
            { name: "应急指挥管理系统" }
          ]
        },
        {
          bg: require("@/assets/slices/2.png"),
          title: "数字景区",
          englishtitle: "SHUZIJINGQU",
          keyword: [
            { name: "数据驾驶仓" },
            { name: "应用支撑平台" },
            { name: "指挥调度系统" },
            { name: "数字孪生系统" },
            { name: "应急指挥中心" },
            { name: "一张图综合管控" },
            { name: "应急管理系统" },
            { name: "SOS报警系统" },
            { name: "工程管控系统" },
            { name: "票务和分销系统" },
            { name: "咨询和投诉系统" },
            { name: "全媒体信息发布" },
            { name: "智能视频监控系统" },
            { name: "智能广播系统" },
            { name: "智能垃圾桶系统" },
            { name: "智能公厕系统" },
            { name: "区域入侵报警系统" },
            { name: "智能路类管控系统" },
            { name: "5G+wifi系统" },
            { name: "森林防火监测系统" },
            { name: "地下管网监测系统" },
            { name: "无人机巡检系统" },
            { name: "物业综合管理系统" },
            { name: "车辆运行系统" },
            { name: "微信小程序" },
            { name: "微信公众号" },
            { name: "多语种官方网站" },
            { name: "二维码系统" },
            { name: "进销存管理系统" },
            { name: "仓库管理系统" },
            { name: "运营营销平台" },
            { name: "虚拟全景视频系统" },
            { name: "舆情监测系统" },
            { name: "诚信信息服务系统" },
            { name: "电子商务系统" },
            { name: "民宿管理系统" },
            { name: "商铺招商管理系统" },
            { name: "户外LED大屏" },
            { name: "触摸屏互动系统" },
            { name: "营销效果评价系统" },
            { name: "AR互动体验系统" },
            { name: "AR视频超融合" }
          ]
        }
      ],
      jjList: [
        // 	{
        // 	img: require('../assets/imgs/2.png'),
        // 	title: '全域旅游',
        // 	value: '通过平台运营，以虚网推动实网建设，以实网资源带动全域资源融合发展。通过虚实双网融合，打造开放共享的数据平台，驱动全域产业发展，形成全域旅游生态圈。'
        // },
        // {
        // 	img: require('../assets/imgs/3.png'),
        // 	title: '旅游大数据',
        // 	value: '为旅游产业提供“规划设计+软件开发+系统集成+市场推广+运营维护”的一体化信息化解决方案。前期规划设计，中期平台建设，后期运营托管。'
        // },
        // {
        // 	img: require('../assets/imgs/4.png'),
        // 	title: '智慧景区',
        // 	value: '为景区建设一套线上线下结合的综合信息化服务系统，全面提升景区信息化水平，优化景区业务流程，提高运营管理效率，为游客提供更好的游览体验。'
        // },
        // {
        // 	img: require('../assets/imgs/5.png'),
        // 	title: '数字乡村',
        // 	value: '致力解决乡村信息孤岛、提高乡村种植科技、加快乡村产业融合、改善乡村人居环境、强化乡村治理效能，打造具有时代性、地域特色的综合性数字乡村应用集群。'
        // },
      ],
      swiperOptionTwo: {
        direction: "horizontal",
        slidesPerView: "auto",
        effect: "coverflow",
        // slidesPerView: 3,
        // centeredSlides: true,
        parallax: true,
        // speed: 2000,
        //  centeredSlides: true,
        spaceBetween: 20,
        //可见图片张数
        slidesPerView: 2,
        // spaceBetween: 10,
        autoplayDisableOnInteraction: false,
        paginationClickable: true,
        loopFillGroupWithBlank: true,
        observer: true,
        observeParents: true,
        // 默认选中中间一张
        centeredSlides: true,
        //无限循环
        loop: true,
        on: {
          click(e) {
            console.log(e, "1111");
            // window.vm.viewTimeTb({
            //   id: e.target.dataset.item,
            // });
          }
        },
        // slideActiveClass : 'my-slide-active',
        //自动轮播
        autoplay: true,
        breakpoints: {
          1200: {
            slidesPerView: 1
          }
        },
        //小圆点（我此处没使用，列出供参考）
        pagination: {
          el: ".swiper-pagination-two",
          clickable: true,
          renderBullet: function (index, className) {
            return '<div class="' + className + '"><span></span></div>';
          }
        },
        //上下按钮点击轮播效果
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      swiperOptionThree: {
        speed: 2000,
        //可见图片张数
        slidesPerView: 1,
        spaceBetween: 5,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,
        paginationClickable: true,
        // 默认选中中间一张
        centeredSlides: true,
        //无限循环
        loop: true,
        on: {
          click(e) {
            console.log(e, "1111");
            // window.vm.viewTimeTb({
            //   id: e.target.dataset.item,
            // });
          }
        },
        // slideActiveClass : 'my-slide-active',
        //自动轮播
        autoplay: true,
        breakpoints: {
          1200: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        },
        //小圆点（我此处没使用，列出供参考）
        pagination: {
          el: ".swiper-pagination-two",
          clickable: true,
          renderBullet: function (index, className) {
            return '<div class="' + className + '"><span></span></div>';
          }
        },
        //上下按钮点击轮播效果
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      swiperOptionFour: {
        // direction:"horizontal",/*横向滑动*/
        spaceBetween: 0,
        //可见图片张数
        slidesPerView: 3,
        // loopedSlides: 4,// 在loop模式下使用slidesPerView，还需使用该参数设置所要用到的loop个数(一般设置大于可视slide个数2个即可)
        loopFillGroupWithBlank: true,
        observer: true, // 改变swiper样式时，自动初始化swiper
        observeParents: true, // 监测swiper父元素，如果有变化则初始化swiper
        autoplayDisableOnInteraction: false,
        paginationClickable: true,
        // 默认选中中间一张
        centeredSlides: true,
        // preventClicks: false,//当swiper在触摸时阻止默认事件（preventDefault），用于防止触摸时触发链接跳转。
        //无限循环
        loop: true,
        on: {
          click(e) {
            console.log(e, "2222");
            // alert('你点了Swiper');
            // console.log(this, "3333");
            console.log(e.target.dataset.item, "3333");
            window.vm.viewTimeTb({
              id: e.target.dataset.item
            });
            document.documentElement.scrollTop = 0;
          }
        },
        //自动轮播
        autoplay: true,
        breakpoints: {
          320: {
            //当屏幕宽度大于等于320
            slidesPerView: 1,
            spaceBetween: 0
          },
          776: {
            //当屏幕宽度大于等于776
            slidesPerView: 2,
            spaceBetween: 0
          },
          1000: {
            //当屏幕宽度大于等于1000
            slidesPerView: 3,
            spaceBetween: 0
          }
        },
        // pagination:'.swiper-pagination',
        //小圆点（我此处没使用，列出供参考）
        pagination: {
          el: ".swiper-pagination",
          clickable: true
          // renderBullet: function (index, className) {
          //   return '<div class="' + className + '"><span></span></div>';
          // }
          // bulletElement: 'li',
        }
      },
      ind: 0,
      buton: [],
      infoList: [],
      contentOneList: [],
      swiperOptionOne: {
        centeredSlides: false,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
        speed: 1000,
        loop: false,
        direction: "horizontal",
        observer: true,
        parallax: true,
        enabled: true,
        observeParents: true,
        // effect: 'creative',
        autoplayDisableOnInteraction: false,
        // 淡入淡出
        effect: "fade",
        fadeEffect: {
          crossFade: true
        },
        //end
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<div class="' + className + '"><span></span></div>';
          }
        }
      },
      isMScreen: false,
      jieshao: {},
      time: 9000,
      moren: "浙江",
      itemArr: {
        北京: [],
        天津: [],
        上海: [],
        重庆: [require("@/assets/shenfen/chonqing/chongqing.png")],
        河北: [require("@/assets/shenfen/hebei/hebei1.png")],
        山西: [],
        辽宁: [],
        吉林: [require("@/assets/shenfen/jilin/jilin.png")],
        黑龙江: [],
        江苏: [
          require("@/assets/shenfen/jiangsu/jiangsu1.png"),
          require("@/assets/shenfen/jiangsu/jiangsu2.png"),
          require("@/assets/shenfen/jiangsu/jiangsu3.png"),
          require("@/assets/shenfen/jiangsu/jiangsu4.png")
        ],
        浙江: [
          require("@/assets/shenfen/zhejiang/1.png"),
          require("@/assets/shenfen/zhejiang/2.png"),
          require("@/assets/shenfen/zhejiang/3.png"),
          require("@/assets/shenfen/zhejiang/4.png"),
          require("@/assets/shenfen/zhejiang/5.png"),
          require("@/assets/shenfen/zhejiang/6.png"),
          require("@/assets/shenfen/zhejiang/7.png"),
          require("@/assets/shenfen/zhejiang/8.png"),
          require("@/assets/shenfen/zhejiang/9.png"),
          require("@/assets/shenfen/zhejiang/10.png"),
          require("@/assets/shenfen/zhejiang/11.png"),
          require("@/assets/shenfen/zhejiang/12.png"),
          require("@/assets/shenfen/zhejiang/13.png"),
          require("@/assets/shenfen/zhejiang/14.png"),
          require("@/assets/shenfen/zhejiang/15.png"),
          require("@/assets/shenfen/zhejiang/16.png"),
          require("@/assets/shenfen/zhejiang/17.png"),
          require("@/assets/shenfen/zhejiang/18.png"),
          require("@/assets/shenfen/zhejiang/19.png"),
          require("@/assets/shenfen/zhejiang/20.png"),
          require("@/assets/shenfen/zhejiang/21.png"),
          require("@/assets/shenfen/zhejiang/22.png"),
          require("@/assets/shenfen/zhejiang/23.png"),
          require("@/assets/shenfen/zhejiang/24.png"),
          require("@/assets/shenfen/zhejiang/25.png"),
          require("@/assets/shenfen/zhejiang/26.png"),
          require("@/assets/shenfen/zhejiang/27.png"),
          require("@/assets/shenfen/zhejiang/28.png")
        ],
        安徽: [
          require("@/assets/shenfen/anhui/anhui1.png"),
          require("@/assets/shenfen/anhui/anhui2.png")
        ],
        福建: [require("@/assets/shenfen/fujian/fujian.png")],
        江西: [],
        山东: [
          require("@/assets/shenfen/shandong/shandong1.png"),
          require("@/assets/shenfen/shandong/shandong2.png"),
          require("@/assets/shenfen/shandong/shandong3.png")
        ],
        河南: [require("@/assets/shenfen/henan/henan.png")],
        湖北: [],
        湖南: [],
        广东: [require("@/assets/shenfen/guangdong/guangdong.png")],
        海南: [],
        四川: [],
        贵州: [],
        云南: [],
        陕西: [],
        甘肃: [],
        青海: [],
        台湾: [],
        内蒙古: [],
        广西: [],
        西藏: [],
        宁夏: [],
        新疆: [require("@/assets/shenfen/xinjiang/xinjiang.png")],
        香港: [],
        澳门: [],
      }
    };
  },
  watch: {
    "$store.state.update.clientWidth"() {
      this.screenWidth = this.$store.state.update.clientWidth;
      this.getScale(this.screenWidth);
      if (this.screenWidth < 1200) {
        this.showdiv = false;
        this.showteam = true;
        this.bannerList = [
          {
            type: "img",
            url: require("@/assets/imgs/phone/dwa@2x.png")
          }
        ];
        var domTheme = (document.getElementById("fp-nav").style.display =
          "none");
      } else {
        this.showdiv = true;
        this.showteam = false;
        console.log("屏幕宽度大于1550px");
      }
    }
  },
  mounted() {
    // this.dataList = allinfo.dataList;
    this.Map();
    // this.getstar();
    if (this.$store.state.update.clientWidth < 1200) {
      this.showdiv = false;
      this.showteam = true;
      this.bannerList = [
        {
          type: "img",
          url: require("@/assets/imgs/phone/dwa@2x.png")
        }
      ];
      var domTheme = (document.getElementById("fp-nav").style.display = "none");
    } else {
      this.showdiv = true;
      this.showteam = false;
    }
    this.screenWidth = document.body.clientWidth;
    console.log(this.$refs.fullpage.api, "超市");

    // this.$nextTick(() => {
    //   this.$wow.init();
    // });
    this.isMScreen = this.$store.state.update.updateWidth;
    this.getData();
    // this.getjjListData();
    // this.getbutonData();
    this.getNews();

    // oList.forEach(function (item) {
    //   console.log(item,item.offsetHeight,"这是什么")
    //   var oHeight = item.offsetHeight;
    //   if ((oHeight / 14) > 2) {
    //     item.className = 'text texter'
    //   }
    // })
    for (let i = 0; i < allinfo.dataList.length; i++) {
      if (allinfo.dataList[i].type == 1) {
        this.lvyou.push(allinfo.dataList[i])
      } else if (allinfo.dataList[i].type == 2) {
        this.jingqu.push(allinfo.dataList[i])
      } else if (allinfo.dataList[i].type == 3) {
        this.xangc.push(allinfo.dataList[i])
      }
    }

  },
  methods: {
    getstar() {
      for (let i = 0; i < allinfo.dataList.length; i++) {
        if (allinfo.dataList[i].type == 1) {
          this.dataList.push(allinfo.dataList[i])
          this.anlilist.push(allinfo.dataList[i].name)
        }
      }
      this.setMap3D();
    },
    anniuone(id) {
      if (id == 1) {
        this.dataList = [];
        this.anlilist = [];
        this.anniushow1 = false;
        this.anniu1 = true;
        this.anniu2 = false;
        this.anniu3 = false;
        this.anniushow2 = true;
        this.anniushow3 = true;
        this.idd = id;
        // map.clearMap();
        for (let i = 0; i < allinfo.dataList.length; i++) {
          if (allinfo.dataList[i].type == 1) {
            this.dataList.push(allinfo.dataList[i])
            // this.marker(this.dataList[i]);
            this.anlilist.push(allinfo.dataList[i].name)

          }
        }
        this.setMap3D();
      } else if (id == 2) {
        this.dataList = [];
        this.anlilist = [];
        this.anniushow2 = false;
        this.anniu2 = true;
        this.anniu1 = false;
        this.anniu3 = false;
        this.anniushow1 = true;
        this.anniushow3 = true;
        this.idd = id;
        // map.clearMap();
        for (let i = 0; i < allinfo.dataList.length; i++) {
          if (allinfo.dataList[i].type == 2) {
            this.dataList.push(allinfo.dataList[i])
            // this.marker(this.dataList[i]);
            this.anlilist.push(allinfo.dataList[i].name)

          }
        }
        this.setMap3D();
      } else if (id == 3) {
        this.dataList = [];
        this.anlilist = [];
        this.anniushow3 = false;
        this.anniu3 = true;
        this.anniu2 = false;
        this.anniu1 = false;
        this.anniushow2 = true;
        this.anniushow1 = true;
        this.idd = id;
        // map.clearMap();
        for (let i = 0; i < allinfo.dataList.length; i++) {
          if (allinfo.dataList[i].type == 3) {
            this.dataList.push(allinfo.dataList[i])
            // this.marker(this.dataList[i]);
            this.anlilist.push(allinfo.dataList[i].name)

          }
        }
        this.setMap3D();
      }
    },
    gaode() {
      var _this = this;
      var SOC = 'CHN'
      var colors = {};
      var GDPSpeed = {
        '520000': 7, //贵州
        '540000': 8.5, //西藏
        '530000': 8, //云南 
        '500000': 7.5, //重庆
        '360000': 6.5, //江西
        '340000': 7.5, //安徽
        '510000': 7.5, //四川
        '350000': 6.5, //福建
        '430000': 7.0, //湖南
        '420000': 7.5, //湖北
        '410000': 6.5, //河南
        '330000': 7.0, //浙江
        '640000': 7.5, //宁夏
        '650000': 9.0, //新疆
        '440000': 7.0, //广东
        '370000': 7.0, //山东
        '450000': 7.3, //广西
        '630000': 8.0, //青海
        '320000': 7.0, //江苏
        '140000': 6.5, //山西
        '460000': 7, // 海南
        '310000': 6.5, //上海
        '110000': 6.5, // 北京
        '130000': 6.5, // 河北
        '230000': 5.5, // 黑龙江
        '220000': 6, // 吉林
        '210000': 6.5, //辽宁
        '150000': 6.5, //内蒙古
        '120000': 5, // 天津
        '620000': 7, // 甘肃
        '610000': 6.5, // 陕西
        '710000': 3, //台湾
        '810000': 3, //香港
        '820000': 5 //澳门
      }
      var getColorByDGP = function (adcode) {
        console.log(adcode, "adcode")
        if (!colors[adcode]) {
          var gdp = GDPSpeed[adcode];
          if (!gdp) {
            colors[adcode] = 'rgb(227,227,227)'
          } else {
            var r = 3;
            var g = 140;
            var b = 230;
            var a = gdp / 10;
            colors[adcode] = 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
          }
        }
        return colors[adcode]
      }
      var disCountry = new AMap.DistrictLayer.Country({
        zIndex: 0,
        SOC: 'CHN',
        depth: 1,
        styles: {
          'nation-stroke': 'RGBA(105, 210, 209, 1)',
          'coastline-stroke': '#0088ff',
          'province-stroke': 'RGBA(105, 210, 209, 1)',
          'fill': function (props) {
            return getColorByDGP(props.adcode_pro)
          }
        }
      })
      function showInfoClick(e) {
        console.log(e)
        var text = '您在 [ ' + e.lnglat.getLng() + ',' + e.lnglat.getLat() + ' ] 的位置单击了地图！'
        console.log(text)
      }
      var opts = {
        subdistrict: 0,
        extensions: 'all',
        level: 'country'
      };
      // let googleMapLayer = new AMap.TileLayer({
      // 	zIndex: 2,
      // 	getTileUrl: function(x, y, z) {
      // 		return require('../assets/icon/shuchu/tiles/' + z + '/' + x + '_' + y + '.png');
      // 	},
      // 	zooms: [3, 8]
      // });
      map = new AMap.Map("mapBox", {
        zooms: [3, 10],
        center: [105.633254, 27.409962],
        zoom: 4.5,
        isHotspot: false,
        defaultCursor: 'pointer',
        layers: [
          disCountry
        ],
        pitch: 30,
        viewMode: '3D',
        resizeEnable: true,
        // features: [],
        // expandZoomRange: true
      })
      map.on('click', showInfoClick);
      // map.on('complete',function(){
      //     var layer = new AMap.LabelsLayer({
      //         // 开启标注避让，默认为开启，v1.4.15 新增属性
      //         collision: false,
      //         // 开启标注淡入动画，默认为开启，v1.4.15 新增属性
      //         animation: true,
      //     });
      //     console.log(LabelsData,"kankan")
      //     for (var i = 0; i < LabelsData.length; i++) {
      //         var labelsMarker = new AMap.LabelMarker(LabelsData[i]);
      //         layer.add(labelsMarker);
      //     }
      //     map.add(layer);
      // })
      var district = new AMap.DistrictSearch(opts);
      district.search('中华人民共和国', function (status, result) {
        var bounds = result.districtList[0].boundaries;
        //添加高度面
        var object3Dlayer = new AMap.Object3DLayer({
          zIndex: 1
        });
        map.add(object3Dlayer)
        var height = -1000000;
        var color = 'RGBA(79, 80, 102, 0.8)'; //rgba
        var wall = new AMap.Object3D.Wall({
          path: bounds,
          height: height,
          color: color
        });
        wall.transparent = true
        object3Dlayer.add(wall)
        //添加描边
        for (var i = 0; i < bounds.length; i += 1) {
          new AMap.Polyline({
            path: bounds[i],
            strokeColor: 'RGBA(105, 210, 209, 1)',
            strokeWeight: 2,
            map: map
          })
        }
        setTimeout(() => {
          _this.addmarker(_this.dataList);
          _this.mapshow = false;
        }, 1000);

      });

    },
    addmarker(json, isclear) {
      if (isclear) {
        map.clearMap();
      }
      for (let i = 0; i < json.length; i++) {
        if (json[i].type == 1) {
          this.marker(json[i]);
        }

      }
    },
    marker(json) {
      var that = this;
      var icon = '';
      if (json.type == 1) {
        icon = require('@/assets/imgs/dituanniu/1.png');
      } else if (json.type == 2) {
        icon = require('@/assets/imgs/dituanniu/2.png');
      } else if (json.type == 3) {
        icon = require('@/assets/imgs/dituanniu/3.png');
      } else if (json.type == 4) {
        // icon = require('@/assets/icon/mapIcon/m4ba.png');
      }
      var myIcon = new AMap.Icon({
        size: new AMap.Size(16, 13),
        image: icon,
        imageSize: new AMap.Size(16, 13)
      });
      marKer = new AMap.Marker({
        map: map,
        position: new AMap.LngLat(json.value[0], json.value[1]),
        icon: myIcon,
        extData: json,
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0, 0), //设置偏移量
      });

      map.add(marKer);
      // 给每个点标记注册点击事件
      marKer.on('click', function () {
        // that.detail(that, this, this.getExtData());
      });
    },
    get(mapName) {
      var option;
      option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          alwaysShowContent: true,
          showDelay: 0, //浮层显示的延迟
          transitionDuration: 0.2, // 提示框浮层的移动动画过渡时间
          enterable: true,
          className: 'iconMap',
          extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0);',
          formatter: function (params) {
            if (params.data) {
              let html = `
									<div class="partitle${params.data.type}">${params.data.name}</div>
									`;
              return html;
            } else {
              return `<div style="background-color: rgba(4,24,51,0.5);padding:5px 10px;">${params.name}</div>`;
            }
          },
          backgroundColor: 'rgba(4,24,51,0)',
          borderColor: 'rgba(99, 173, 222,0)',
          borderWidth: 0,
          padding: [0, 0],
          position: 'top',
          textStyle: {
            color: '#fff',
            fontWeight: 'bolder',
          },
        },
        geo: [
          {
            map: mapName,
            aspectScale: 0.8,
            layoutCenter: ["50%", "50%"],
            layoutSize: "100%",
            itemStyle: {
              normal: {
                areaColor: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0,
                    color: "#1D6496" // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#3E85C2" // 100% 处的颜色
                  }
                  ],
                  global: false // 缺省为 false
                },
                shadowColor: "#A8D7FF",
                shadowOffsetX: 0,
                shadowOffsetY: 15,
                opacity: 1,
              },
              emphasis: {
                areaColor: "#0f5d9d",
              },
            },

            regions: [
              {
                name: "南海诸岛",
                itemStyle: {
                  areaColor: "rgba(0, 10, 52, 1)",
                  borderColor: "rgba(0, 10, 52, 1)",
                  normal: {
                    opacity: 0,
                    label: {
                      show: false,
                      color: "#009cc9",
                    },
                  },
                },
                label: {
                  show: false,
                  color: "#FFFFFF",
                  fontSize: 12,
                },
              },
            ],
          }
        ],
        series: [{
          type: "map",
          selectedMode: "multiple",
          mapType: mapName,
          geoIndex: 1,
          aspectScale: 0.8,
          layoutCenter: ["50%", "50%"], //地图位置
          layoutSize: "100%",
          zoom: 1, //当前视角的缩放比例
          roam: true, //是否开启平游或缩放
          scaleLimit: {
            //滚轮缩放的极限控制
            min: 1,
            max: 10,
          },
          label: {
            show: false,
            color: "#FFFFFF",
            fontSize: 16,
          },
          itemStyle: {
            normal: {
              areaColor: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: "#2B4D7A" // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#2B4D7A" // 100% 处的颜色
                }
                ],
                global: false // 缺省为 false
              },
              // shadowColor: "#0f5d9d",
              borderColor: "#56B1DA",
              borderWidth: 2,
              opacity: 0.8,
            },
            emphasis: {
              areaColor: "#56b1da",
              label: {
                show: false,
                color: "#fff",
              },
            },
          },
          data: this.dataList,
        },

        {
          //标记点图片
          type: 'scatter',
          coordinateSystem: 'geo',
          itemStyle: {
            color: '#f00',
            opacity: 1,
          },
          symbol: function (value, params) {
            if (params.data.isActive) {
              let iconActive = '';
              if (params.data.type == 1) {
                iconActive = 'image://' + require('@/assets/imgs/dituanniu/cd.png')
              } else if (params.data.type == 2) {
                iconActive = 'image://' + require('@/assets/imgs/dituanniu/hd.png')
              } else if (params.data.type == 3) {
                iconActive = 'image://' + require('@/assets/imgs/dituanniu/qd.png')
              }
              return iconActive;
            } else {
              let icon = '';
              if (params.data.type == 1) {
                icon = 'image://' + require('@/assets/imgs/dituanniu/cd.png')
              } else if (params.data.type == 2) {
                icon = 'image://' + require('@/assets/imgs/dituanniu/hd.png')
              } else if (params.data.type == 3) {
                icon = 'image://' + require('@/assets/imgs/dituanniu/qd.png')
              }
              return icon;
            }
          },
          symbolSize: [16, 20],
          symbolOffset: [0, -15],
          zlevel: 9999,
          data: this.dataList,
          animation: true,
          animationEasing: 'quarticIn',
          animationDurationUpdate: 0, //数据更新动画的时长。
        },

        {
          type: 'effectScatter',
          coordinateSystem: 'geo',
          rippleEffect: {
            number: 1,
            scale: 3,
            brushType: 'stroke',
          },
          showEffectOn: 'render',
          itemStyle: {
            normal: {
              shadowColor: function (params) {
                if (params.data.type == 1) {
                  return '#F99B56'
                } else if (params.data.type == 2) {
                  return '#F6F159'
                } else if (params.data.type == 3) {
                  return '#00FFE0'
                }
              },
              shadowBlur: 3,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              color: function (params) {
                var colorList = [
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      offset: 0,
                      color: '#f926d3',
                    },
                    {
                      offset: 1,
                      color: '#f926d3',
                    },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      offset: 0,
                      color: '#F99B56',
                    },
                    {
                      offset: 1,
                      color: '#F99B56',
                    },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      offset: 0,
                      color: '#F6F159',
                    },
                    {
                      offset: 1,
                      color: '#F6F159',
                    },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      offset: 0,
                      color: '#00FFE0',
                    },
                    {
                      offset: 1,
                      color: '#00FFE0',
                    },
                  ])
                ];
                return colorList[params.data.type];
              },
            },
          },
          label: {
            normal: {
              color: '#fff',
            },
          },
          symbol: 'circle',
          symbolSize: [10, 5],
          data: this.dataList,
          zlevel: 1,
        },
        ],
      };
      option && this.myChart.setOption(option);
    },
    // 初始化地图
    setMap3D() {
      let zheJiangMap = require('@/assets/json/zhejiang.json');
      let chinaJson = require('@/assets/json/china.json');
      var chartDom = document.getElementById('mapBox');
      this.myChart = echarts.init(chartDom);
      var mapName = "china";
      echarts.registerMap(mapName, chinaJson);
      this.get(mapName);
      this.myChart.on('mousemove', (event) => {
        if (event.componentSubType === 'scatter') {
          set(event);
        }
      });

      // this.myChart.on('click', (event) => {
      //   console.log(event)
      //   if (event.data != undefined) {
      //     this.xmmc = event.name
      //     this.imgsimgs = event.data.imgs
      //     console.log(event.data.imgs)
      //   }
      //   mapName = event.name
      //   this.anlilist = [];
      //   for (let i = 0; i < allinfo.dataList.length; i++) {
      //     if (allinfo.dataList[i].sf == event.name && allinfo.dataList[i].type == this.idd) {
      //       this.anlilist.push(allinfo.dataList[i].name)
      //     }
      //   }
      //   if (event.name == '浙江') {
      //     console.log('浙江')
      //     this.dataList = [];
      //     this.anlilist = [];
      //     echarts.registerMap(mapName, zheJiangMap);
      //     for (let i = 0; i < allinfo.dataList.length; i++) {
      //       if (allinfo.dataList[i].sf == event.name && allinfo.dataList[i].type == this.idd) {
      //         this.dataList.push(allinfo.dataList[i])
      //         this.anlilist.push(allinfo.dataList[i].name)
      //       }
      //     }
      //     this.get(mapName);
      //   }
      // })
      // 高亮
      let set = (event) => {
        this.dataList.forEach((v, i) => {
          if (v.name != event.name) {
            if (v.isActive) {
              v.isActive = false;
              v.value[2] = [40, 50];
              v.value[3] = [0, -25];
            }
          } else {
            v.isActive = true;
            v.value[2] = [60, 68];
            v.value[3] = [0, -20];
          }
        });
      };
      //echarts 设置地图外边框以及多个geo实现缩放拖曳同步
      this.myChart.on('georoam', (params) => {
        var option = this.myChart.getOption(); //获得option对象
        if (params.zoom != null && params.zoom != undefined) {
          //捕捉到缩放时
          option.geo[0].zoom = option.series[0].zoom; //下层geo的缩放等级跟着上层的geo一起改变
          option.geo[0].center = option.series[0].center; //下层的geo的中心位置随着上层geo一起改变
        } else {
          //捕捉到拖曳时
          option.geo[0].center = option.series[0].center; //下层的geo的中心位置随着上层geo一起改变
        }
        this.myChart.setOption(option); //设置option
      });

      this.mapshow = false;
    },
    Map() {
      let chinaJson = require('@/assets/json/china.json');
      var chartDom = document.getElementById('mapBox');
      this.myChart = echarts.init(chartDom);
      echarts.registerMap('china', chinaJson);
      let option;
      var chinaGeoCoordMap = {
        '黑龙江': [127.9688, 45.368],
        '内蒙古': [110.3467, 41.4899],
        "吉林": [125.8154, 44.2584],
        '北京市': [116.4551, 40.2539],
        "辽宁": [123.1238, 42.1216],
        "河北": [114.4995, 38.1006],
        "天津": [117.4219, 39.4189],
        "山西": [112.3352, 37.9413],
        "陕西": [109.1162, 34.2004],
        "甘肃": [103.5901, 36.3043],
        "宁夏": [106.3586, 38.1775],
        "青海": [101.4038, 36.8207],
        "新疆": [87.611053, 43.828171],
        "西藏": [91.117212, 29.646922],
        "四川": [103.9526, 30.7617],
        "重庆": [108.384366, 30.439702],
        "山东": [117.1582, 36.8701],
        "河南": [113.4668, 34.6234],
        "江苏": [118.8062, 31.9208],
        "安徽": [117.29, 32.0581],
        "湖北": [114.3896, 30.6628],
        "浙江": [119.5313, 29.8773],
        "福建": [119.4543, 25.9222],
        "江西": [116.0046, 28.6633],
        "湖南": [113.0823, 28.2568],
        "贵州": [106.6992, 26.7682],
        "云南": [102.9199, 25.4663],
        "广东": [113.12244, 23.009505],
        "广西": [108.479, 23.1152],
        "海南": [110.3893, 19.8516],
        "台湾": [120.702967, 24.123621],
        '上海': [121.4648, 31.2891]
      };
      var chinaDatas = [
        [{
          name: "北京市",
          value: 0
        }, {
          name: '黑龙江',
          value: 0
        }, { name: '上海市' }],
        [{
          name: '内蒙古',
          value: 0
        }], [{
          name: '吉林',
          value: 0
        }], [{
          name: '辽宁',
          value: 0
        }], [{
          name: '河北',
          value: 0
        }], [{
          name: '天津',
          value: 0
        }], [{
          name: '山西',
          value: 0
        }], [{
          name: '陕西',
          value: 0
        }], [{
          name: '甘肃',
          value: 0
        }], [{
          name: '新疆',
          value: 0
        }], [{
          name: '西藏',
          value: 0
        }], [{
          name: '台湾',
          value: 0
        }], [{
          name: '黑龙江',
          value: 0
        }], [{
          name: '云南',
          value: 0
        }], [{
          name: '宁夏',
          value: 0
        }], [{
          name: '青海',
          value: 0
        }], [{
          name: '四川',
          value: 0
        }], [{
          name: '重庆',
          value: 0
        }], [{
          name: '山东',
          value: 0
        }], [{
          name: '河南',
          value: 0
        }], [{
          name: '江苏',
          value: 0
        }], [{
          name: '安徽',
          value: 0
        }], [{
          name: '湖北',
          value: 0
        }], [{
          name: '浙江',
          value: 0
        }], [{
          name: '福建',
          value: 0
        }], [{
          name: '江西',
          value: 0
        }], [{
          name: '湖南',
          value: 0
        }], [{
          name: '贵州',
          value: 0
        }], [{
          name: '广西',
          value: 0
        }], [{
          name: '海南',
          value: 0
        }], [{
          name: '上海',
          value: 0
        }]
      ];

      var convertData = function (data) {

        var res = [];
        for (var i = 0; i < data.length; i++) {
          var dataItem = data[i];
          var fromCoord = chinaGeoCoordMap[dataItem[0].name];
          var toCoord = [[119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773], [119.5313, 29.8773]];//被攻击点
          if (fromCoord && toCoord[i]) {
            res.push([{
              coord: fromCoord,
            }, {
              coord: toCoord[i],
              value: dataItem[0].value,
              // visualMap: false
            }]);
          }
        }
        console.log(res, '看看是啥data')
        return res;
      };

      var series = [];
      [['浙江', chinaDatas]].forEach(function (item, i) {
        console.log(item)
        series.push({
          type: 'map',
          mapType: 'china',
          geoIndex: 1,
          aspectScale: 0.8,
          layoutCenter: ["50%", "50%"], //地图位置
          layoutSize: '100%',
          zoom: 1, //当前视角的缩放比例
          // roam: true, //是否开启平游或缩放
          scaleLimit: { //滚轮缩放的极限控制
            min: 1,
            max: 2
          },
          itemStyle: {
            normal: {
              areaColor: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: "#2B4D7A" // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#2B4D7A" // 100% 处的颜色
                }
                ],
                global: false // 缺省为 false
              },
              // shadowColor: "#0f5d9d",
              borderColor: "#56B1DA",
              borderWidth: 2,
              opacity: 0.8,
            },
            emphasis: {
              areaColor: '#56b1da',
              label: {
                color: "#fff"
              }

            }
          }
        }, {
          type: 'lines',
          zlevel: 2,
          effect: {
            show: true,
            period: 3, //箭头指向速度，值越小速度越快
            trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
            symbol: 'arrow', //箭头图标
            symbolSize: 5, //图标大小
          },
          lineStyle: {
            normal: {
              color: '#00eaff',
              width: 1, //尾迹线条宽度
              opacity: .7, //尾迹线条透明度
              curveness: .3, //尾迹线条曲直度
            },
          },
          data: convertData(item[1])
        },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 2,
            rippleEffect: { //涟漪特效
              period: 4, //动画时间，值越小速度越快
              brushType: 'stroke', //波纹绘制方式 stroke, fill
              scale: 4 //波纹圆环最大限制，值越大波纹越大
            },
            // label: {
            //   normal: {
            //     show: true,
            //     position: 'right', //显示位置
            //     offset: [5, 0], //偏移设置
            //     formatter: function (params) {//圆环显示文字
            //       return params.data.name;
            //     },
            //     fontSize: 13
            //   },
            //   emphasis: {
            //     show: false
            //   }
            // },
            symbol: 'circle',
            symbolSize: function (val) {
              return 5 + val[2] * 5; //圆环大小
            },
            itemStyle: {
              normal: {
                show: true,
                color: '#00eaff'
              }
            },
            data: item[1].map(function (dataItem) {
              return {
                name: dataItem[0].name,
                value: chinaGeoCoordMap[dataItem[0].name].concat([dataItem[0].value]),
                // visualMap: false
              };
            }),
          },
          //被攻击点
          // {
          //   type: 'scatter',
          //   coordinateSystem: 'geo',
          //   zlevel: 2,
          //   rippleEffect: {
          //     period: 4,
          //     brushType: 'stroke',
          //     scale: 4
          //   },
          //   label: {
          //     normal: {
          //       show: false,    //定位点名字
          //       position: 'right',
          //       // offset:[5, 0],
          //       color: '#0f0',
          //       formatter: '{b}',
          //       textStyle: {
          //         color: "#0f0"
          //       }
          //     },
          //     emphasis: {
          //       // show: false,   //定位标记
          //       color: "#f60"
          //     }
          //   },
          //   symbol: 'pin',  //定位图标样式
          //   symbolSize: 50,
          //   data: [{
          //     name: item[0],
          //     value: chinaGeoCoordMap[item[0]].concat([10]),
          //   }],
          // }
        );
      });
      option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          alwaysShowContent: true,
          showDelay: 0, //浮层显示的延迟
          transitionDuration: 0.2, // 提示框浮层的移动动画过渡时间
          enterable: true,
          className: 'iconMap',
          extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0);',
          formatter: function (params) {
            if (params.data) {
              let html = `
									<div class="partitle${params.data.type}">${params.data.name}</div>
									`;
              return html;
            } else {
              return `<div style="background-color: rgba(4,24,51,0.5);padding:5px 10px;">${params.name}</div>`;
            }
          },
          backgroundColor: 'rgba(4,24,51,0)',
          borderColor: 'rgba(99, 173, 222,0)',
          borderWidth: 0,
          padding: [0, 0],
          position: 'top',
          textStyle: {
            color: '#fff',
            fontWeight: 'bolder',
          },
        },
        // backgroundColor: '#061d4d',
        geo: {
          map: 'china',
          aspectScale: 0.85,
          layoutCenter: ["50%", "50%"], //地图位置
          layoutSize: '100%',
          itemStyle: {
            normal: {
              areaColor: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: "#1D6496" // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#3E85C2" // 100% 处的颜色
                }
                ],
                global: false // 缺省为 false
              },
              shadowColor: '#A8D7FF',
              shadowOffsetX: 0,
              shadowOffsetY: 15,
              opacity: 1,
            },
            emphasis: {
              areaColor: '#0f5d9d',

            }
          },
          regions: [{
            name: '南海诸岛',
            itemStyle: {
              areaColor: 'rgba(0, 10, 52, 1)',

              borderColor: 'rgba(0, 10, 52, 1)',
              normal: {
                opacity: 0,
                label: {
                  show: false,
                  color: "#009cc9",
                }
              },


            },
            label: {
              show: false,
              color: '#FFFFFF',
              fontSize: 12,
            },


          }],

        },
        series: series
      };
      this.myChart.setOption(option);
    },
    changeArr(data) {
      // let newdata;
      if (data.length > 0) {
        chartOption.renderItem = function (params, api) {
          return {
            type: "image",
            name: "aaa",
            style: {
              image: require("@/assets/guang.png"), //标注点图标
              width: 14,
              height: 18,
              x: api.coord([
                data[params.dataIndex].value[0],
                data[params.dataIndex].value[1]
              ])[0],
              y: api.coord([
                data[params.dataIndex].value[0],
                data[params.dataIndex].value[1]
              ])[1]
            }
          };
        };
      }
    },
    numAutoPlus(targetEle, xiaoshu, options) {
      options = options || {};
      // 获取dom元素
      let $this = document.getElementById(targetEle);
      // 动画时长(毫秒数)
      // 也可以将需要的参数写在dom上如：[data-XXX]
      let time = options.time || $this.getAttribute("data-time");
      // 最终要显示的数字
      let finalNum = options.num || $this.getAttribute("data-time");
      // 调节器(毫秒数) 改变数字增加速度
      let rate = options.rate || $this.getAttribute("data-rate");
      // 步长
      let step = finalNum / (time / rate);
      // 计数器
      let count = 0;
      // 初始值
      let initNum = 0;
      // 定时器
      let timer = setInterval(function () {
        count = count + step;
        if (count >= finalNum) {
          clearInterval(timer);
          count = finalNum;
        }
        // t未发生改变的话就直接返回
        // 减少dom操作 提高DOM性能
        // let t = Math.floor(count)
        let t = count.toFixed(xiaoshu);
        if (t === initNum) return;
        initNum = t;
        $this.innerHTML = initNum;
      }, 30);
    },
    //解决方法跳转
    viewTimeTb(item) {
      console.log(item, "opp");
      this.$router.push({
        path: "/totalSolution",
        query: {
          tab: item.id
        }
      });
    },
    //新闻详情
    news(v) {
      let vv = JSON.stringify(v);
      window.localStorage.setItem("key", vv);
      this.$router.push({
        path: "/newsxq"
      });
      document.documentElement.scrollTop = 0;
    },
    //鼠标移入移出
    addActive(index) {
      this.inx = index;
    },
    removeActive(index) {
      this.inx = 0;
    },
    jumpTop() {
      this.$refs.fullpage.api.moveSectionDown();
    },
    jumpTop1() {
      this.$refs.fullpage.api.moveTo(1);
    },
    jumpto(id) {
      this.$router.push({
        path: "/kehual",
        query: {
          tab: id
        }
      });
      document.documentElement.scrollTop = 0;
    },
    jumplj(v) {
      console.log(v, "v");
      let vv = JSON.stringify(v);
      window.localStorage.setItem("key", vv);
      this.$router.push({
        path: "/khalxq"
        // query: {
        //   title1: v.newsTitle,
        //   valuehtml:v.content
        // }
      });
      document.documentElement.scrollTop = 0;
    },
    formatter: function (num) {
      return num.toFixed(0);
      this.$refs.fullpage.api.moveSectionDown();
    },
    startAnimate: function () {
      this.$refs.myNum.start();
    },
    afterLoad(key, index) {
      // console.log(key, "师姐", index);
      if (index.index == 1) {
        this.numAutoPlus("numValue", 0, {
          time: 2000,
          num: 14,
          rate: 40
        });
        this.numAutoPlus("numValue1", 0, {
          time: 2000,
          num: 65,
          rate: 40
        });
        this.numAutoPlus("numValue2", 0, {
          time: 2000,
          num: 95,
          rate: 40
        });
        this.numAutoPlus("numValue3", 0, {
          time: 2000,
          num: 300,
          rate: 40
        });
      }
      let kIndex =
        index.index > 0 && index.index != 6
          ? index.index - 1
          : index.index == 6
            ? index.index - 2
            : index.index;
      const Jy = document.getElementsByClassName("gytit");
      const Dy = document.getElementsByClassName("about");
      const Jf = document.getElementById("jjleft");
      const JR = document.getElementById("jjright");
      const Htm = document.getElementById("htm");
      const p1 = document.getElementById("p1");
      // const p2 = document.getElementById("p2");
      Jy[kIndex].classList.add("animate__animated");
      Jy[kIndex].classList.add("animate__fadeInDown");
      Jy[kIndex].classList.add("o-d");
      Dy[kIndex].classList.add("animate__animated");
      Dy[kIndex].classList.add("animate__fadeInLeft");
      Dy[kIndex].classList.add("o-d");
      // console.log(kIndex, "超市");
      if (index.index == 1) {
        console.log("pppjinlai");
        if (this.screenWidth < 1200) {
          var oList = document.querySelectorAll(".htm");
          this.$nextTick(() => {
            // console.log(oList[0].offsetHeight, "你你你")
            oList.forEach(function (item) {
              var oHeight = item.offsetHeight;
              if (oHeight / 24 > 5) {
                item.className = "text texter";
              }
            });
          });
        }
        if (this.screenWidth > 1200) {
          var oList = document.querySelectorAll(".htm");
          this.$nextTick(() => {
            // console.log(oList[0].offsetHeight, "你你你")
            oList.forEach(function (item) {
              var oHeight = item.offsetHeight;
              if (oHeight / 24 > 5) {
                item.className = "text texter";
              }
            });
          });
        }

        // console.log(oList[0].offsetHeight, "你你你")
        // Jf.classList.add("animate__animated");
        // Jf.classList.add("animate__fadeInLeft");
        // Jf.classList.remove("o-p");
        JR.classList.add("animate__animated");
        JR.classList.add("animate__fadeInDown");
        JR.classList.remove("o-p");
        p1.classList.add("animate__animated");
        p1.classList.add("animate__fadeInUp");
        p1.classList.remove("o-p");
        p1.style.setProperty("--animate-duration", "1.5s");
        // p2.classList.add("animate__animated");
        // p2.classList.add("animate__fadeInUp");
        // p2.classList.add("animate__delay-0.5s");
        // p2.classList.remove("o-p");
        p1.style.setProperty("--animate-duration", "1.5s");
        Htm.classList.add("animate__animated");
        Htm.classList.add("animate__fadeInUp");
        Htm.classList.add("animate__delay-1s");
        Htm.classList.remove("o-p");
        Htm.style.setProperty("--animate-duration", "1.5s");
        // Htm.style.setProperty('--animate-delay', '2s');
        setTimeout(function () {
          // Jf.classList.remove("animate__animated");
          // Jf.classList.remove("animate__fadeInLeft");
          JR.classList.remove("animate__animated");
          JR.classList.remove("animate__fadeInDown");
          p1.classList.remove("animate__animated");
          p1.classList.remove("animate__fadeInUp");
          // p2.classList.remove("animate__animated");
          // p2.classList.remove("animate__fadeInUp");
          Htm.classList.remove("animate__animated");
          Htm.classList.remove("animate__fadeInUp");
          //   JR.classList.remove("o-p");
          //   Jf.classList.remove("o-p");
        }, 2000); //延时时长一般是动画的duration
      }
      setTimeout(function () {
        Jy[kIndex].classList.remove("animate__animated");
        Jy[kIndex].classList.remove("animate__fadeInDown");
        Dy[kIndex].classList.remove("animate__animated");
        Dy[kIndex].classList.remove("animate__fadeInLeft");
      }, 1000); //延时时长一般是动画的duration
      //   }
    },
    getScale() {
      let ww = window.innerWidth / this.width;
      let wh = window.innerHeight / this.height;
      return ww < wh ? ww : wh;
    },
    setScale: _.debounce(function () {
      let scale = this.getScale();
      this.scale = scale;
    }, 500),
    gent(path, tab) {
      this.$router.push({
        path: path,
        query: {
          tab: tab
        }
      });
      document.documentElement.scrollTop = 0;
    },
    jump(v) {
      console.log(v, "v");
      let vv = JSON.stringify(v);
      window.localStorage.setItem("key", vv);
      this.$router.push({
        path: "/khalxq"
        // query: {
        //   title1: v.newsTitle,
        //   valuehtml:v.content
        // }
      });
      document.documentElement.scrollTop = 0;
    },
    //轮播
    handleTwoPrev(e) {
      this.$refs.mySwiperTwo.swiper.navigation.onPrevClick(e);
    },
    handleTwoNext(e) {
      this.$refs.mySwiperTwo.swiper.navigation.onNextClick(e);
    },
    handleThreePrev(e) {
      this.$refs.mySwiperThree.swiper.navigation.onNextClick(e);
    },
    handleThreeNext(e) {
      this.$refs.mySwiperThree.swiper.navigation.onPrevClick(e);
    },
    // 简介
    getData() {
      let data;
      data = {
        pageSize: 99,
        pageNum: 1,
        columnId: 3 //骥宇天哲/公司简介
      };
      getview(data).then(res => {
        this.jieshao = res.data.rows[0];
      });
    },
    //新闻
    getNews() {
      let data;
      data = {
        pageSize: 999,
        pageNum: 1,
        columnId: 16 //骥宇天哲/公司简介
      };
      // if (this.$store.state.update.clientWidth < 1200) {
      //   data = {
      //     pageSize: 4,
      //     pageNum: 1,
      //     columnId: 16 //骥宇天哲/公司简介
      //   };
      // } else {
      //   data = {
      //     pageSize: 3,
      //     pageNum: 1,
      //     columnId: 16 //骥宇天哲/公司简介
      //   };
      // }

      getview(data).then(res => {
        res.data.rows.sort((a, b) => b.sort - a.sort)
        this.newsList = res.data.rows;
        this.newsList.forEach(v => {
          v.releaseTime = v.releaseTime.slice(0, 10);
        });
      });
    },
    // 解决方案
    getjjListData() {
      let data;
      data = {
        pageSize: 99,
        pageNum: 1,
        columnId: 6 //解决方案
      };
      getview(data).then(res => {
        let arr = res.data.rows;
        console.log(arr, "你怎么看");
        // for(var i=0 ;i<this.jjfa.length;i++){
        arr.forEach(m => {
          if (m.newsTitle == "数字文旅") {
            this.jjfa[0].value = m.digest;
          }
          if (m.newsTitle == "旅游大数据") {
            this.jjfa[1].value = m.digest;
          }
          if (m.newsTitle == "智慧景区") {
            this.jjfa[2].value = m.digest;
          }
          if (m.newsTitle == "数字乡村") {
            this.jjfa[3].value = m.digest;
          }
        });
        // }
      });
    },
    // 案例栏目
    getbutonData() {
      let data;
      data = {
        pageSize: 99,
        pageNum: 1,
        parentId: 7 //案例
      };
      getNotice(data).then(res => {
        this.buton = res.data.rows;
        console.log(this.buton, "火龙");
        this.getinfoListData();
      });
    },
    // 案例新闻
    getinfoListData() {
      let data;
      data = {
        pageSize: 6,
        pageNum: 1,
        columnId: 9 //案例
      };
      getview(data).then(res => {
        this.infoList = res.data.rows;
        // console.log(this.infoList, "你你你");
        for (var i = 0; i < 6; i++) {
          this.contentOneList.push({
            newsTitle: this.infoList[i].newsTitle,
            img: this.infoList[i].imagesUrls,
            value: this.infoList[i].digest,
            content: this.infoList[i].content
          });
        }
      });
    },
    initSwiper() {
      var swiperOptionTwo = new Swiper(this.$refs.mySwiperTwo, {
        //可见图片张数
        slidesPerView: 2,
        spaceBetween: 10,
        autoplayDisableOnInteraction: false,
        paginationClickable: true,
        // 默认选中中间一张
        centeredSlides: true,
        //无限循环
        loop: true,
        on: {
          click(e) {
            console.log(e, "1111");
            // window.vm.viewTimeTb({
            //   id: e.target.dataset.item,
            // });
          }
        },
        // slideActiveClass : 'my-slide-active',
        //自动轮播
        autoplay: true,
        breakpoints: {
          668: {
            slidesPerView: 1
          }
        },
        //小圆点（我此处没使用，列出供参考）
        pagination: {
          el: ".swiper-pagination-two",
          clickable: true,
          renderBullet: function (index, className) {
            return '<div class="' + className + '"><span></span></div>';
          }
        },
        //上下按钮点击轮播效果
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
    },
    handerBanner(index) { },
    handleTwoPrev(e) {
      this.$refs.mySwiperTwo.swiper.navigation.onPrevClick(e);
    },
    handleTwoNext(e) {
      this.$refs.mySwiperTwo.swiper.navigation.onNextClick(e);
    },
    navClick(v, s) {
      this.ind = s;
      this.getinfoListData(v.id);
    },
    arrowClick(val) {
      if (val === "next") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    }
  }
};
</script>
<style lang="scss">
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span {
  background: #fff;
  /*这里设置的是活动导航的颜色*/
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border: 0.0625rem solid #fff;
  /*这里设置的是非活动导航的颜色*/
}

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span :hover {
  background: #fff;
  /*这里设置的是活动导航的颜色*/
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span :hover {
  border: 0.0625rem solid #fff;
  /*这里设置的是非活动导航的颜色*/
}
</style>

<style scoped lang="scss">
/* 1200> max*/
@media screen and (min-width: 75rem) {
  @import "@/assets/fonts/font.css";

  //   ::v-deep .el-carousel__item.is-animating {

  // transition: opacity .1s ease-in-out !important;
  // }

  // .wrapper >>>

  #appHome {
    .head {
      display: none;
    }

    .flg {
      cursor: pointer;

      ::v-deep .section {
        // height: 100vh !important;

        .fp-tableCell {

          // height: 100vh !important;
          .contOne,
          .contTwo,
          .contThree,
          .contFour,
          .contFive {
            height: 100vh !important;
          }
        }
      }
    }

    #china_echart {
      width: 1024px;
      height: 634px;
    }

    .case {
      display: flex;

      // justify-content: space-between;
      .caseText {
        margin-left: 4.125rem;

        .casetitle {
          width: 41.875rem;
          height: 3.375rem;
          background: #4383d7;
          box-sizing: border-box;
          padding: 0 28px;
          line-height: 3.375rem;
          font-size: 24px;
          font-family: OPPOSans-Medium, OPPOSans;
          font-weight: 500;
          color: #ffffff;
        }

        .casemain {
          width: 41.875rem;
          height: 35.75rem;
          background: #d7f1fe;
          margin-top: 0.625rem;
          overflow: auto;
          box-sizing: border-box;
          padding: 0.8rem 1.875rem 0.625rem 1.875rem;
          scrollbar-width: none;

          .casecontent {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            .casebox {
              // -webkit-animation: 5s rowdown linear infinite normal;
              // animation: 5s rowdown linear infinite normal;
              // position: relative;
              width: 8.75rem;
              height: 5.3125rem;
              margin-bottom: 0.625rem;

              >img {
                min-width: 100%;
                height: 100%;
                overflow: hidden;
                background: #fff;
              }
            }
          }
        }

        /* // 设置滚动条宽度 */
        .casemain::-webkit-scrollbar {
          width: 9px !important;
          height: 8px !important;
          background: #4383d7;
          // background: red;
        }

        .casemain::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          box-shadow: inset 0 0 5px #4383d7;
          border-radius: 10px;
          background: #0e1e3c;
        }

        /* //滚动条的哈滑块 */
        .casemain::-webkit-scrollbar-thumb {
          background: #4383d7;
          // background: #fff;
          border-radius: 3px;
        }
      }
    }

    // .flg:hover ~ .head {
    //   display: block !important;
    //   transition: all 0.5s linear 0s !important;
    // }

    .flg:hover~.icon {
      display: block !important;
      transition: all 0.5s linear 0s !important;
    }

    .flg:hover~.ts {
      display: block !important;
      transition: all 0.5s linear 0s !important;
    }
  }

  .o-p {
    opacity: 0;
  }

  .o-d {
    opacity: 1;
  }

  .topCon {
    position: relative;

    .bannerWrap {
      position: relative;

      .prev {
        width: 90%;
        display: flex;
        justify-content: space-between;
        z-index: 3;
        position: absolute;
        top: 42%;
        left: 43%;
        transform: translate(-42%, -42%);

        .prevItem {
          width: 3rem;
          height: 6rem;
          text-align: center;
          border-radius: 0.25rem;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }

          .prevItem1 {
            display: block;
          }

          .prevItem2 {
            display: none;
          }
        }

        .prevItem:hover {
          .prevItem1 {
            display: none;
          }

          .prevItem2 {
            display: block;
            opacity: 1;
            transition: all 0.5s linear 0s;
          }
        }

        .prevItems {
          width: 3rem;
          height: 6rem;
          text-align: center;
          border-radius: 0.25rem;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }

          .prevItem3 {
            display: block;
          }

          .prevItem4 {
            display: none;
          }
        }

        .prevItems:hover {
          .prevItem3 {
            display: none;
          }

          .prevItem4 {
            display: block;
            opacity: 1;
            transition: all 0.5s linear 0s;
          }
        }

        // .prevItem:hover {
        //   background: #3455fc;
        //   color: #fff;
        // }
      }

      .title {
        width: 80%;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;

        .immm {
          width: 36.875rem;
          height: 4.5rem;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .qy {
          font-size: 3.75rem;
          color: #fff;
          position: relative;
          top: -3.75rem;

          span {
            margin: 0 0.4375rem;
            font-family: BDZYJT;
            font-weight: bold;
          }
        }

        .qy span:nth-child(1) {
          animation: shan 1s both;
          // text-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
        }

        .qy span:nth-child(2) {
          animation: shan 1s 1.2s both;
        }

        .qy span:nth-child(3) {
          animation: shan 1s 1.4s both;
        }

        .qy span:nth-child(4) {
          animation: shan 1s 1.6s both;
        }

        .qy span:nth-child(5) {
          animation: shan 1s 1.8s both;
        }

        .qy span:nth-child(6) {
          animation: shan 1s 2s both;
        }

        @keyframes ani {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        .tit1 {
          font-size: 1.375rem;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 1.875rem;
          letter-spacing: 0.5625rem;
          margin-top: 1.1875rem;

          .tit {
            margin: 0 1.875rem;
          }
        }

        .tit2 {
          font-size: 0.875rem;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 1.1875rem;
          margin-top: 1.25rem;
          display: flex;
          justify-content: center;
          align-items: center;

          .tittwo {
            span {
              &:first-child {
                margin: 0 0.3125rem;
              }
            }
          }
        }
      }
    }
  }

  .icon {
    width: 5%;
    z-index: 3;
    position: fixed;
    top: 77%;
    right: 0%;
    transform: translate(-42%, -42%);
    display: none;
    cursor: pointer;

    // .dh {
    //   display: flex;

    //   .dianhua {
    //     float: left;
    //     display: none;
    //     width: 140px;
    //     height: 48px;
    //   }
    // }

    .iconbg {
      margin-top: 0.75rem;
      text-align: right;
      cursor: pointer;
      position: relative;

      .imgicon {
        width: 3rem;
        height: 3rem;
        background: regb(0, 0, 0, 0.1);

        img {
          width: 100%;
          height: 100%;
        }
      }

      .dianhua {
        // float: left;
        position: absolute;
        right: 100%;
        top: 32%;
        transform: translate(-10%, -10%);
        display: none;
        width: 8.75rem;
        height: 3rem;
      }
    }

    .iconbg:hover {
      .imgicon {
        background: #3396fb;
      }

      .dianhua {
        display: block;
        background: regb(0, 0, 0, 0.5);

        span {
          font-size: 1.25rem;
          font-family: Impact;
          color: #ffffff;
          line-height: 1.5rem;
        }
      }
    }
  }

  .icon:hover {
    display: block;
  }

  .scorll {
    width: 3.125rem;
    height: 3.125rem;
    border: 1px solid #ffffff;
    border-radius: 50%;
    transition: all 0.5s;
    position: relative;
    margin: 0 auto;
    cursor: pointer;

    &:hover .sqll {
      animation: gobottom 1s infinite;
    }

    @keyframes gobottom {
      0% {
        transform: translate(-50%, -50%);
      }

      50% {
        transform: translate(-50%, 15px);
      }

      100% {
        transform: translate(-50%, -50%);
      }
    }

    .sqll {
      width: 0;
      height: 0;
      border: 0.375rem solid transparent;
      border-top: 0.375rem solid #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover+.huadong>div {

      // animation: shan 0.5s linear infinite;
      // text-transform: uppercase;
      &:nth-child(1) {
        animation: shan 1s both;
      }

      &:nth-child(2) {
        animation: shan 2s both;
      }

      &:nth-child(3) {
        animation: shan 3s both;
      }

      &:nth-child(4) {
        animation: shan 4s both;
      }

      &:nth-child(5) {
        animation: shan 5s both;
      }

      &:nth-child(6) {
        animation: shan 6s both;
      }
    }

    @keyframes shan {
      0% {
        color: transparent;
      }

      100% {
        color: aliceblue;
        text-shadow: 0 0 0.25rem skyblue, 0 0 0.625rem skyblue,
          0 0 1.25rem skyblue, 0 0 1.875rem skyblue, 0 0 2.5rem skyblue,
          0 0 3.125rem skyblue;
        // 0 0 60px skyblue,
        // 0 0 70px skyblue,
        // 0 0 80px skyblue,
        // 0 0 90px skyblue,
        // 0 0 100px skyblue,
        // 0 0 110px skyblue,
        // 0 0 120px skyblue,
        // 0 0 130px skyblue;
        /* 文字阴影叠加 */
      }
    }
  }

  .huadong {
    div {
      display: inline-block;
    }

    >div:nth-child(1) {
      animation-delay: 0s;
    }

    >div:nth-child(2) {
      animation-delay: 0.4s;
    }

    >div:nth-child(3) {
      animation-delay: 0.8s;
    }

    >div:nth-child(4) {
      animation-delay: 1.2s;
    }

    >div:nth-child(5) {
      animation-delay: 1.6s;
    }

    >div:nth-child(6) {
      animation-delay: 2s;
    }

    >div:nth-child(7) {
      animation-delay: 2.4s;
    }

    >div:nth-child(8) {
      animation-delay: 2.8s;
    }

    >div:nth-child(9) {
      animation-delay: 3.2s;
    }
  }

  .ts {
    color: white;
    z-index: 3;
    position: fixed;
    bottom: 7%;
    left: 48%;
    display: none;
    cursor: pointer;
  }

  .ts:hover {
    display: block;
  }

  .gb {
    text-align: center;

    span {
      margin-left: 0.9375rem;
    }

    >div {
      display: inline-block;
    }
  }

  .iconbg {
    margin-top: 0.75rem;
    text-align: right;
    cursor: pointer;

    .iconbg {
      margin-top: 0.75rem;
      text-align: right;
      cursor: pointer;

      .imgicon {
        width: 3rem;
        height: 3rem;
        background: regb(0, 0, 0, 0.1);

        img {
          width: 100%;
          height: 100%;
        }
      }

      // .dianhua {
      //   float: left;
      //   position: absolute;
      //   right: 92px;
      //   top: 85px;
      //   display: none;
      //   width: 140px;
      //   height: 48px;
      // }
    }

    .iconbg:hover {
      .imgicon {
        background: #3396fb;
      }

      .dianhua {
        display: block;
        background: regb(0, 0, 0, 0.5);

        span {
          font-size: 1.25rem;
          font-family: Impact;
          color: #ffffff;
          line-height: 1.5rem;
        }
      }
    }
  }

  .contOne {
    // min-width: 1920px;
    // height: 100vh;
    min-height: 53.125rem;
    background: url("@/assets/newimgs/b2@2x.jpg");
    position: relative;
    padding: 9.375rem 0rem 0rem 0rem;
    background-size: 100% 100%;

    .gyjy {
      width: 75rem;
      margin: 0 auto;
      position: relative;

      .gytit {
        font-size: 1.5rem;
        font-family: MicrosoftYaHeiSemibold;
        color: #ffffff;
        line-height: 2rem;
        text-align: center;
      }

      .about {
        font-size: 1.5rem;
        font-family: impact;
        color: #ffffff;
        opacity: 0.3;
        line-height: 2.0625rem;
        text-align: center;
        position: absolute;
        top: 85%;
        left: 46%;
        transform: translate(-9%, -9%);
        letter-spacing: 4px;
      }

      .top_xian {
        position: absolute;
        top: 178%;
        left: 48%;
        margin: auto;
        width: 3.3125rem;
        height: 0.25rem;
        background: #ffffff;
        border-radius: 0.0625rem;
      }
    }

    .gsjj {
      margin-top: 8.75rem;
      display: flex;
      // overflow: hidden;
      z-index: 2;
      position: absolute;
      left: 36%;
      top: 27%;
      transform: translate(-41%, -31%);

      .jjleft {
        // width: 500px;
        width: 37.5rem;
        height: 25rem;
        position: relative;
        text-align: center;

        .xian_left {
          width: 37.5rem;
          height: 0.3125rem;
          background: #3396fb;
          border-radius: 1px;
          margin: auto;
        }

        .jjleft_one {
          width: 31.25rem;
          height: 18.75rem;
          background: #ffffff;
          opacity: 0.9;
          bottom: 0%;
          text-align: left;
          padding: 3.125rem;
          overflow: hidden;

          p {
            font-size: 1.25rem;
            font-family: MicrosoftYaHeiSemibold;
            color: #333333;
            line-height: 1.6875rem;
            margin: 0;
          }

          .xian {
            width: 2.125rem;
            height: 0.25rem;
            background: #3396fb;
            border-radius: 0.0625rem;
            margin: auto;
            z-index: 33;
            position: absolute;
            top: 24%;
            left: 9%;
          }

          .htm {
            width: 31.25rem;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            margin-top: 7%;
            position: relative;

            ::v-deep p {
              line-height: 1.5625rem;
              margin: 0;
            }
          }

          .texter {
            height: 136px;
            position: relative;
            overflow: hidden;
            line-height: 24px;
            margin-top: 1.25rem;
          }

          .texter:after {
            content: " ... ";
            position: absolute;
            bottom: -1px;
            right: 0px;
            padding-left: 5px;
            // color: #fff;
            background: -webkit-linear-gradient(left, transparent, #fff 40%);
            /* Safari 5.1-6.0 */
            background: -o-linear-gradient(left, #fff 50%);
            /* Opera 11.1-12.0 */
            background: -moz-linear-gradient(left, #fff 50%);
            /* Firefox 3.6-15 */
            background: linear-gradient(to left, #fff, #fff);
            /* 标准语法 */
            /* 标准语法 */
          }

          .QHcolor2 {
            width: 5.5rem;
            background: #3396fb;
            position: absolute;
            bottom: 11%;
            cursor: pointer;
            font-size: 0.875rem;
            font-family: MicrosoftYaHei;
            color: #ffffff;
            height: 2rem;
            line-height: 2rem;
            text-align: center;
          }

          .QHcolor2:hover {
            background: #333333;
            transition: all 0.5s linear 0s;
          }
        }
      }

      .bjj {
        // background: url("../assets/imgs/18bj.png") no-repeat;
        // background-size: 100% 100%;
        background: #ffffff;
        width: 800px;
        height: 120px;
        display: flex;
        justify-content: space-around;
        position: absolute;
        top: 71%;
        left: 66%;
        z-index: 1;
        //   opacity: 1;

        .oneone {
          width: 200px;
          height: 120px;
          text-align: center;
          cursor: pointer;

          #root {
            font-size: 34px;
            font-family: Impact;
            color: #3396fb;
            margin-top: 10px;
          }

          p {
            font-size: 18px;
            font-family: Helvetica;
            color: #333333;
          }
        }

        .oneone2 {
          width: 200px;
          height: 120px;
          text-align: center;
          background: #3396fb;
          z-index: 999;

          #root {
            font-size: 34px;
            font-family: Impact;
            color: #ffffff;
            margin-top: 10px;
          }

          p {
            font-size: 18px;
            font-family: Helvetica;
            color: #ffffff;
          }
        }

        .oneone:hover {
          background: #3396fb;
          transition: all 0.5s linear 0s;

          #root {
            color: #ffffff;
            transition: all 0.5s linear 0s;
          }

          p {
            color: #ffffff;
            transition: all 0.5s linear 0s;
          }
        }

        // .oneone:hover ~ .oneone2 {
        //   background: #fff;

        //   #root {
        //     color: #3396fb;
        //   }

        //   p {
        //     color: #333333;
        //   }
        // }
      }

      .bjj>.oneone:hover .oneone2 {
        background: #fff;

        #root {
          color: #3396fb;
        }

        p {
          color: #333333;
        }
      }
    }
  }

  .contTwo {
    // min-width: 1920px;
    background: url("../assets/newimgs/b3@2x.png") no-repeat;
    background-size: 100% 100%;
    min-height: 53.125rem;
    position: relative;
    padding: 9.375rem 0rem 0rem 0rem;

    // margin-top: 24%;
    .gyjy {
      width: 75rem;
      margin: 0 auto;
      position: relative;

      .gytit {
        font-size: 1.5rem;
        font-family: MicrosoftYaHeiSemibold;
        color: #ffffff;
        line-height: 2rem;
        text-align: center;
      }

      .about {
        font-size: 1.5rem;
        font-family: impact;
        color: #ffffff;
        opacity: 0.3;
        line-height: 2.0625rem;
        text-align: center;
        position: absolute;
        top: 100%;
        left: 43%;
        transform: translate(-9%, -9%);
        letter-spacing: 4px;
      }
    }

    .top_xian {
      position: absolute;
      top: 190%;
      left: 48%;
      margin: auto;
      width: 3.3125rem;
      height: 0.25rem;
      background: #ffffff;
      border-radius: 0.0625rem;
    }
  }

  .youshi {
    width: 87.5rem;
    margin: 0 auto;
    padding: 3.125rem;

    .wmdys {
      width: 87.5rem;
      display: flex;
      justify-content: center;
      // margin-bottom: 80px;
      margin-top: 3.125rem;
      cursor: pointer;
      box-sizing: border-box;

      .ysbox {
        // padding: 30px;
        // transition: all 0.5s linear 0s;
        overflow: hidden;
        width: 15rem;
        height: 27.5rem;
        box-sizing: border-box;

        .imgss {
          width: 4.25rem;
          height: 3.75rem;
          margin: 1.25rem auto;
          margin-top: 5.5rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .ysname {
          font-size: 1.875rem;
          font-family: MicrosoftYaHeiSemibold;
          color: #ffffff;
          line-height: 2.5rem;
          text-align: center;
        }

        .bianhao {
          margin-top: 1.125rem;
          font-size: 0.875rem;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 1.1875rem;
          text-align: center;
          position: relative;
        }
      }
    }

    .ysbox:hover {
      background: rgba(255, 255, 255, 0.2);
      border-top: 0.25rem solid #3396fb;
      transition: all 0.5s linear 0s;
      transform: scale(1.2);
      transform-origin: center center;
    }
  }
}

.contThree {
  background: url("../assets/newimgs/bj4@2x.jpg") no-repeat;
  background-size: 100% 100%;
  min-height: 53.125rem;
  padding: 9.375rem 0rem 0rem 0rem;

  .gyjy {
    width: 75rem;
    margin: 0 auto;
    position: relative;

    .gytit {
      font-size: 1.5rem;
      font-family: MicrosoftYaHeiSemibold;
      color: #ffffff;
      line-height: 2rem;
      text-align: center;
    }

    .about {
      font-size: 1.5rem;
      font-family: impact;
      color: #ffffff;
      opacity: 0.3;
      line-height: 2.0625rem;
      text-align: center;
      position: absolute;
      top: 100%;
      left: 44%;
      transform: translate(-9%, -9%);
      letter-spacing: 2px;
    }
  }

  .top_xian {
    position: absolute;
    top: 200%;
    left: 48%;
    margin: auto;
    width: 3.3125rem;
    height: 0.25rem;
    background: #ffffff;
    border-radius: 0.0625rem;
  }
}

.total {
  // width: 75rem;
  margin: 3.125rem auto;
  display: flex;
  justify-content: center;

  .solon {
    img {
      height: 31.25rem;
      width: 11.25rem;
      transition: 0.8s;
      cursor: pointer;
      margin: 0 0.625rem;
    }

    .return {
      width: 23.9375rem;
      height: 29.5rem;
      background: rgba(242, 249, 255, 0.9);
      border-radius: 0.625rem;
      display: none;
      margin: 0 0.625rem;

      p {
        &:first-child {
          font-size: 1.625rem;
          font-family: Microsoft YaHei;
          font-weight: 600;
          color: #333333;
          text-align: center;
          margin: 0 auto;
          margin-top: 0.8125rem;
          padding: 1.875rem 0rem 0rem 0rem;
          box-sizing: border-box;
        }

        &:nth-child(2) {
          font-size: 0.75rem;
          font-family: HarmonyOS Sans SC;
          font-weight: 100;
          color: #333333;
          opacity: 0.5;
          margin: 0;
          text-align: center;
          margin-top: 0.625rem;
        }
      }

      .seamless-warp {
        height: 16.875rem;
        width: 22.5rem;
        margin: 1rem auto;
        overflow: hidden;
        list-style: none;

        .key {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          -webkit-animation: 10s rowup linear infinite normal;
          animation: 10s rowup linear infinite normal;
          position: relative;

          .keyname {
            height: 2.25rem;
            width: 10.125rem;
            box-sizing: border-box;
            text-align: center;
            background: #cbe2f4;
            font-size: 0.875rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin: 0.4375rem;
            padding: 0rem 0.9375rem;
            line-height: 2.25rem;
            align-items: center;
            cursor: pointer;
          }

          .keyname:hover {
            background: #3396fb;
            color: #fff;
            transition: all 0.3s linear 0s;
          }
        }
      }
    }
  }

  @keyframes rowup {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -webkit-transform: translate3d(0, -15rem, 0);
      transform: translate3d(0, -15rem, 0);
    }
  }

  @keyframes rowdown {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    100% {
      -webkit-transform: translate3d(0, -5rem, 0);
      transform: translate3d(0, -5rem, 0);
    }
  }

  .solon:hover {
    transform: scale(1.05);
    transition: all 0.5s linear 0s;
    z-index: 999;

    img {
      display: none;
    }

    .return {
      display: block;
    }
  }

  .qybt {
    width: 18.75rem;
    height: 25rem;
    position: relative;
    transition: 0.8s;
    cursor: pointer;
    // /* 3D变形 */
    // transform-style: preserve-3d;
    // -webkit-perspective: 1000;
    // -moz-perspective: 1000;
    // -ms-perspective: 1000;
    // perspective: 1000;

    .bboo {
      width: 3.875rem;
      height: 3.875rem;
      position: absolute;
      top: 30%;
      left: 48%;
      transform: translate(-40%, -40%);

      img {
        width: 100%;
        height: 100%;
        // overflow: hidden;
        // transition: all 0.8s;
      }
    }

    // img:hover {
    //   transform: scale(1.03);
    // }
    >.xian {
      width: 3.125rem;
      height: 0.125rem;
      background: #ffffff;
      border-radius: 0.0625rem;
      margin: auto;
      z-index: 0;
      position: absolute;
      top: 51%;
      left: 49%;
      transform: translate(-41%, -40%);
    }

    .totalbox {
      position: absolute;
      top: 69%;
      left: 48%;
      transform: translate(-41%, -40%);
      text-align: center;

      span {
        &:first-child {
          font-size: 1.25rem;
          font-family: MicrosoftYaHeiSemibold;
          color: #ffffff;
        }

        &:nth-child(3) {
          font-size: 0.75rem;
          font-family: MicrosoftYaHei;
          color: #ffffff;
        }
      }
    }

    .block {
      padding: 5rem 1.875rem 0;
      background: #3396fb;
      height: 100%;
      transition: 0.8s;
      opacity: 0;
      text-align: center;
      color: #fff;
      box-sizing: border-box;
      display: none;

      span {
        &:first-child {
          font-size: 1.25rem;
          font-family: MicrosoftYaHeiSemibold;
          color: #ffffff;
        }

        &:nth-child(3) {
          font-size: 0.75rem;
          font-family: MicrosoftYaHei;
          color: #ffffff;
        }
      }

      .xian {
        width: 3.125rem;
        height: 0.125rem;
        background: #ffffff;
        border-radius: 0.0625rem;
        margin: auto;
        z-index: 33;
        display: block;
        //   position: absolute;
        //   top: 51%;
        //   left: 49%;
        //   transform: translate(-41%, -40%);
      }

      .sp {
        margin-top: 1.25rem;
        text-align: center;
        font-family: impact;
      }
    }

    // .block:hover {
    //   transform: rotateY(180deg);
    //   z-index: 999;
    // }

    //   .block:hover + .xian {
    //     opacity: 0;
    //   }
  }

  .qybt:hover {
    // transform: rotateY(180deg);
    transform: scale(1.05);
    transition: all 0.5s linear 0s;
    z-index: 999;

    .block {
      //   transform: scale(1.05);
      display: block;
    }

    .bboo {
      display: none;
    }

    >.xian {
      display: none;
    }

    .totalbox {
      display: none;
    }
  }

  .qybt:hover .block {
    opacity: 1;
    //   transform: scale(1.05);
  }
}

.moreCont {
  width: 5.625rem;
  margin: 0 auto;
  cursor: pointer;

  .QHcolor2 {
    width: 5.5rem;
    cursor: pointer;
    font-size: 0.875rem;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    height: 2rem;
    line-height: 2rem;
    border: 1px solid #ffffff;
    text-align: center;
  }

  .QHcolor2:hover {
    background: #3396fb;
    transition: all 0.5s linear 0s;
    border: 0.0625rem solid #3396fb;
  }
}

.viewTime {
  // margin-top: -120px;
  position: relative;
  z-index: 9;
  cursor: pointer;
}

.bbbox {
  // width: 1400px;
  width: 70%;
  margin: 0 auto;

  .contentItem {
    margin-top: 3.125rem;

    .twoCard {
      background: #fff;
      // max-width: 260px;
      // max-height: 500px;
      overflow: hidden;
      padding-bottom: 1.875rem;
      // box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 7.5rem;
      border-radius: 1.0625rem 1.0625rem 0.625rem 0.625rem;
      padding: 0.625rem;
      margin: auto;

      .images {
        // width: 230px;
        // height: 230px;
        width: 15vw;
        height: 15vw;
        border-radius: 50%;
        // padding: 10px;
        margin: 0 auto;
        background-size: auto 100% !important;
      }

      .titles {
        font-size: 1.375rem;
        font-family: MicrosoftYaHeiSemibold;
        color: #333333;
        padding: 0rem 0.125rem 0rem 0.125rem;
        position: relative;
        text-align: center;

        .xian {
          position: absolute;
          bottom: -0.625rem;
          left: 44%;
          margin: auto;
          width: 2rem;
          height: 0.125rem;
          background: #3396fb;
          border-radius: 0.0625rem;
        }
      }

      .trans {
        margin: 1.5625rem auto;
        // width: 220px;
        // height: 150px;
        padding: 0rem 1.25rem;
        line-height: 1.625rem;
      }
    }

    .twoPrev {
      position: absolute;
      left: -15%;
      top: 40%;
      cursor: pointer;
      z-index: 3;
    }

    .twoNext {
      position: absolute;
      right: -15%;
      top: 40%;
      cursor: pointer;
      z-index: 3;
    }
  }
}

.contFour {
  background: url("../assets/imgs/di@2x.png") no-repeat;
  background-size: 100% 100%;
  min-height: 53.125rem;
  padding: 9.375rem 0rem 0rem 0rem;

  .gyjy {
    width: 75rem;
    margin: 0 auto;
    position: relative;

    .gytit {
      font-size: 1.5rem;
      font-family: MicrosoftYaHeiSemibold;
      color: #ffffff;
      line-height: 2rem;
      text-align: center;
    }

    .about {
      font-size: 1.5rem;
      font-family: impact;
      color: #ffffff;
      opacity: 0.3;
      line-height: 2.0625rem;
      text-align: center;
      position: absolute;
      top: 100%;
      left: 9%;
      transform: translate(-9%, -9%);
      letter-spacing: 2px;
      width: 100%;
    }

    .top_xian {
      position: absolute;
      top: 200%;
      left: 48%;
      transform: translate(-9%, -9%);
      margin: auto;
      width: 3.3125rem;
      height: 0.25rem;
      background: #ffffff;
      border-radius: 0.0625rem;
    }
  }

  .swiper-container {
    // widows: 960px;
    margin: 1.875rem auto;

    ::v-deep .swiper-slide-active {
      .con {
        opacity: 1 !important;
        transition: all 0.5s linear 0s !important;
        // transform: scale(1.2) !important;
      }
    }

    // ::v-deep .swiper-slide-next {
    //   transform: scale(.7);
    // }

    // ::v-deep .swiper-slide-prev {
    //   transform: scale(.7);
    // }

    .oneSwiper {
      position: relative;
      display: flex;

      .item {
        .item_m {
          display: block;
          margin: 0 1.25rem;
          position: relative;

          .con {
            position: absolute;
            left: 56px;
            right: 56px;
            top: 80px;
            opacity: 0;
            text-align: center;
            transition: all 300ms ease;
            // position: absolute;
            // left: 50%;
            // top: 85%;
            // transform: translate(-50%, -50%);
            // opacity: 0;
            transition: all 300ms ease;
            // background: #FFFFFF;
            // border-top: 4px solid #3396FB;
            // width: 50%;
            // height: 225px;
            // padding: 20px 40px;
            // box-sizing: border-box;

            .con_title {
              font-size: 22px;
              font-family: MicrosoftYaHeiSemibold;
              color: #ffffff;
              line-height: 30px;
            }

            .con_value {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              line-height: 24px;
              margin-top: 30px;
              min-height: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
            }

            .con_lj {
              margin: 0 auto;
              margin-top: 40px;

              .moreCont2 {
                width: 90px;
                margin: 0 auto;
                cursor: pointer;

                .QHcolor3 {
                  width: 88px;
                  cursor: pointer;
                  font-size: 14px;
                  font-family: MicrosoftYaHei;
                  background: #fff;
                  color: #333333;
                  height: 2rem;
                  line-height: 2rem;
                  text-align: center;
                }

                .QHcolor3:hover {
                  background: #3396fb;
                  color: #fff;
                  transition: all 0.5s linear 0s;
                }
              }
            }
          }

          .imgsss {
            height: 31.25rem;
            // width: 900px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .twoPrev {
      position: absolute;
      left: 5rem;
      top: 40%;
      cursor: pointer;
      z-index: 3;
      width: 5.125rem;
      height: 3rem;

      img {
        width: 100%;
        height: 100%;
      }

      .twoPrev1 {
        display: block;
      }

      .twoPrev2 {
        display: none;
      }
    }

    .twoPrev:hover {
      .twoPrev1 {
        display: none;
      }

      .twoPrev2 {
        display: block;
        transition: all 1s linear 0s;
      }
    }

    .twoNext {
      position: absolute;
      right: 6.875rem;
      top: 40%;
      cursor: pointer;
      z-index: 3;
      width: 5.125rem;
      height: 3rem;

      img {
        width: 100%;
        height: 100%;
      }

      .twoNext1 {
        display: block;
      }

      .twoNext2 {
        display: none;
      }
    }

    .twoNext:hover {
      .twoNext1 {
        display: none;
      }

      .twoNext2 {
        display: block;
        transition: all 1s linear 0s;
      }
    }
  }
}

.contFive {
  background: url("../assets/imgs/b6.png") no-repeat;
  background-size: 100% 100%;
  min-height: 53.125rem;
  // padding: 9.375rem 0rem 0rem 0rem;

  .gyjy {
    width: 75rem;
    margin: 0 auto;
    position: relative;
    padding-top: 10.625rem;

    .gytit {
      font-size: 1.5rem;
      font-family: MicrosoftYaHeiSemibold;
      color: #ffffff;
      line-height: 2rem;
      text-align: center;
    }

    .about {
      font-size: 1.5rem;
      font-family: impact;
      color: #ffffff;
      opacity: 0.3;
      line-height: 2.0625rem;
      text-align: center;
      position: absolute;
      top: 100%;
      left: 42%;
      transform: translate(-9%, -9%);
      letter-spacing: 4px;
    }

    .top_xian {
      position: absolute;
      top: 115%;
      left: 48%;
      margin: auto;
      width: 3.3125rem;
      height: 0.25rem;
      background: #ffffff;
      border-radius: 0.0625rem;
    }
  }

  .news {
    display: flex;
    justify-content: center;
    margin: 5.375rem auto 3rem;

    .newslist {
      width: 24rem;
      height: 27rem;
      background: #ffffff;
      // border: 0.0625rem solid #cccccc;
      margin-bottom: 1.875rem;
      margin: 0.625rem;
      position: relative;

      .newstime {
        position: absolute;
        top: -55px;
        font-size: 20px;
        font-family: MicrosoftYaHeiSemibold;
        color: #ffffff;
        margin: 20px 0;
      }

      // overflow: hidden;
      // transition: all 0.5s;
      .date-img {
        width: 100%;
        height: 13.75rem;
        // border: 0.0625rem solid;

        .imgs {
          // width: 24rem;
          // height: 16.25rem;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .nn {
        .titlediv {
          width: 97%;
          height: 1.5rem;
          font-size: 1.125rem;
          font-family: MicrosoftYaHeiSemibold;
          color: #333333;
          line-height: 1.5rem;
          font-weight: bold;
          margin-left: 1rem;
          margin-top: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          // margin-left: 0.9375rem;
          // margin-right: 0.9375rem;
        }

        .con-txt {
          height: 4.875rem;
          font-size: 0.875rem;
          font-family: MicrosoftYaHei;
          color: #666666;
          line-height: 1.625rem;
          margin-top: 1rem;
          margin-left: 1rem;
          margin-right: 1rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }

      .ljxq2 {
        width: 6.25rem;
        height: 1.1875rem;
        font-size: 0.875rem;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 1.1875rem;
        margin-top: 2.25rem;
        margin-left: 0.9375rem;
        cursor: pointer;

        img {
          margin-left: 0.625rem;
        }
      }

      .ljxq2:hover {
        color: #3396fb;

        img {
          content: url("@/assets/newimgs/jiantou.png");
        }
      }
    }
  }

  // .sk {
  //   margin-top: 6.25rem;

  //   .sk2 {
  //     width: 890px;
  //     display: flex;
  //     flex-wrap: wrap;
  //     margin: 0 auto;

  //     .lianjie {
  //       display: flex;
  //       flex-wrap: wrap;
  //       margin: 0.625rem;

  //       .sp {
  //         width: 275px;
  //         height: 115px;
  //         overflow: hidden;
  //         background: #FFFFFF;
  //         // background: rgba(255, 255, 255, 0.3);
  //         cursor: pointer;
  //         padding: 1.25rem;
  //         box-sizing: border-box;

  //         img {
  //           width: 100%;
  //           height: 100%;
  //           overflow: hidden;
  //           transition: all 0.5s;
  //           // opacity: 0.5;
  //         }

  //         img:hover {
  //           // opacity: 1;
  //           transform: scale(1.05);
  //         }
  //       }
  //     }
  //   }
  // }

  // 按钮
  // .btn-boxs {
  //   position: absolute;
  //   top: 2.8125rem;
  //   right: 6.25rem;
  //   z-index: 9;

  //   .btn-left,
  //   .btn-right {
  //     display: inline-block;
  //     width: 2.625rem;
  //     height: 2.625rem;
  //     // border-radius: 50%;
  //     // border: 0.0625rem solid #5d6168;
  //     // background: rgba(255, 255, 255, 0.8);
  //     margin: 0 0.625rem;
  //     cursor: pointer;
  //   }

  //   .btn-left {

  //     div {
  //       width: 2.5rem;
  //       display: flex;
  //       position: absolute;
  //       right: 2.5rem;
  //       top: -0.3125rem;

  //       img {
  //         &:first-child {
  //           width: 100%;
  //           height: 100%;
  //           display: block;
  //         }

  //         &:nth-child(2) {
  //           width: 100%;
  //           height: 100%;
  //           display: none;
  //         }
  //       }
  //     }
  //   }

  //   .btn-right {

  //     div {
  //       width: 2.5rem;
  //       display: flex;
  //       position: absolute;
  //       right: 5.9375rem;
  //       top: -0.3125rem;

  //       img {
  //         &:first-child {
  //           width: 100%;
  //           height: 100%;
  //           display: block;
  //         }

  //         &:nth-child(2) {
  //           width: 100%;
  //           height: 100%;
  //           display: none;
  //         }
  //       }
  //     }
  //   }

  //   .btn-right:hover {

  //     div {
  //       img {
  //         &:first-child {
  //           display: none;
  //         }

  //         &:nth-child(2) {
  //           display: block;
  //         }
  //       }
  //     }
  //   }

  //   .btn-left:hover {
  //     div {
  //       img {
  //         &:first-child {
  //           display: none;
  //         }

  //         &:nth-child(2) {
  //           display: block;
  //         }
  //       }
  //     }
  //   }
  // }
}

#sec7 {
  height: auto !important;

  ::v-deep .fp-tableCell {
    height: auto !important;
  }
}

.footerCon {
  position: relative;
  z-index: 99;
  width: 100%;
  background-color: #343433;
  display: flex;
  justify-content: center;
}

::v-deep .el-carousel__button {
  width: 6.5rem;
}

::v-deep .el-carousel__indicator--horizontal {
  display: inline-block;
  padding: 3.4375rem 0.625rem;
}
</style>
<style scoped lang="scss">
/* 0~1200px */
@media screen and (max-width: 1200px) {
  @import "@/assets/fonts/font.css";

  #appHome {
    .head {
      display: none;
    }

    .flg {
      cursor: pointer;

      ::v-deep .section {
        // height: 100vh !important;

        .fp-tableCell {

          // height: 100vh !important;
          .contOne,
          .contTwo,
          .contThree,
          .contFour,
          .contFive {
            height: 100vh !important;
          }
        }
      }
    }

    // .flg:hover~.icon {
    //   display: block !important;
    //   transition: all 0.5s linear 0s !important;
    // }

    // .flg:hover~.ts {
    //   display: block !important;
    //   transition: all 0.5s linear 0s !important;
    // }
  }

  .o-p {
    opacity: 0;
  }

  .o-d {
    opacity: 1;
  }

  .topCon {
    position: relative;

    .bannerWrap {
      position: relative;

      .prev {
        width: 90%;
        display: flex;
        justify-content: space-between;
        z-index: 3;
        position: absolute;
        top: 42%;
        left: 43%;
        transform: translate(-42%, -42%);
        display: none;

        .prevItem {
          width: 3rem;
          height: 6rem;
          text-align: center;
          border-radius: 0.25rem;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }

          .prevItem1 {
            display: block;
          }

          .prevItem2 {
            display: none;
          }
        }

        .prevItem:hover {
          .prevItem1 {
            display: none;
          }

          .prevItem2 {
            display: block;
            opacity: 1;
            transition: all 0.5s linear 0s;
          }
        }

        .prevItems {
          width: 3rem;
          height: 6rem;
          text-align: center;
          border-radius: 0.25rem;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }

          .prevItem3 {
            display: block;
          }

          .prevItem4 {
            display: none;
          }
        }

        .prevItems:hover {
          .prevItem3 {
            display: none;
          }

          .prevItem4 {
            display: block;
            opacity: 1;
            transition: all 0.5s linear 0s;
          }
        }
      }

      .title {
        width: 100%;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;

        .immm {
          width: 19.375rem;
          height: 2.375rem;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .qy {
          font-size: 2.125rem;
          color: #fff;
          position: relative;
          top: -1.5rem;

          span {
            font-family: BDZYJT--GB1-0, BDZYJT--GB1;
            font-weight: normal;
            line-height: 2.375rem;
            letter-spacing: 0.3125rem;
            margin: 0 0.4375rem;
          }
        }

        .qy span:nth-child(1) {
          animation: shan 1s both;
        }

        .qy span:nth-child(2) {
          animation: shan 1s 1.2s both;
        }

        .qy span:nth-child(3) {
          animation: shan 1s 1.4s both;
        }

        .qy span:nth-child(4) {
          animation: shan 1s 1.6s both;
        }

        .qy span:nth-child(5) {
          animation: shan 1s 1.8s both;
        }

        .qy span:nth-child(6) {
          animation: shan 1s 2s both;
        }

        @keyframes ani {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        .tit1 {
          font-size: 0.8125rem;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 1.0625rem;
          margin-top: 1.1875rem;

          .tit {
            margin: 0 0.3125rem;
          }
        }

        .tit2 {
          font-size: 0.6875rem;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 0.9375rem;
          margin-top: 1.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          .tittwo {
            span {
              &:first-child {
                margin: 0 0.625rem;
              }
            }
          }
        }
      }
    }
  }

  .icon {
    width: 5%;
    z-index: 3;
    position: fixed;
    top: 81%;
    right: 4%;
    transform: translate(-42%, -42%);
    display: none;
    cursor: pointer;

    .iconbg {
      margin-top: 0.75rem;
      text-align: right;
      cursor: pointer;
      position: relative;

      .imgicon {
        width: 3rem;
        height: 3rem;
        background: regb(0, 0, 0, 0.1);

        img {
          width: 100%;
          height: 100%;
        }
      }

      .dianhua {
        // float: left;
        position: absolute;
        right: 4.25rem;
        top: 0.5625rem;
        display: none;
        width: 8.75rem;
        height: 3rem;
      }
    }

    .iconbg:hover {
      .imgicon {
        background: #3396fb;
      }

      .dianhua {
        display: block;
        background: regb(0, 0, 0, 0.5);

        span {
          font-size: 1.25rem;
          font-family: Impact;
          color: #ffffff;
          line-height: 1.5rem;
        }
      }
    }
  }

  .icon:hover {
    display: block;
  }

  .scorll {
    width: 3.125rem;
    height: 3.125rem;
    border: 0.0625rem solid #ffffff;
    border-radius: 50%;
    transition: all 0.5s;
    position: relative;
    margin: 0 auto;
    cursor: pointer;

    &:hover .sqll {
      animation: gobottom 1s infinite;
    }

    @keyframes gobottom {
      0% {
        transform: translate(-50%, -50%);
      }

      50% {
        transform: translate(-50%, 15px);
      }

      100% {
        transform: translate(-50%, -50%);
      }
    }

    .sqll {
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-top: 0.375rem solid #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover+.huadong>div {

      // animation: shan 0.5s linear infinite;
      // text-transform: uppercase;
      &:nth-child(1) {
        animation: shan 1s both;
      }

      &:nth-child(2) {
        animation: shan 2s both;
      }

      &:nth-child(3) {
        animation: shan 3s both;
      }

      &:nth-child(4) {
        animation: shan 4s both;
      }

      &:nth-child(5) {
        animation: shan 5s both;
      }

      &:nth-child(6) {
        animation: shan 6s both;
      }
    }

    @keyframes shan {
      0% {
        color: transparent;
      }

      100% {
        color: aliceblue;
        text-shadow: 0 0 0.25rem skyblue, 0 0 0.625rem skyblue,
          0 0 1.25rem skyblue, 0 0 1.875rem skyblue, 0 0 2.5rem skyblue,
          0 0 3.125rem skyblue;
      }
    }
  }

  .huadong {
    div {
      display: inline-block;
    }

    >div:nth-child(1) {
      animation-delay: 0s;
    }

    >div:nth-child(2) {
      animation-delay: 0.4s;
    }

    >div:nth-child(3) {
      animation-delay: 0.8s;
    }

    >div:nth-child(4) {
      animation-delay: 1.2s;
    }

    >div:nth-child(5) {
      animation-delay: 1.6s;
    }

    >div:nth-child(6) {
      animation-delay: 2s;
    }

    >div:nth-child(7) {
      animation-delay: 2.4s;
    }

    >div:nth-child(8) {
      animation-delay: 2.8s;
    }

    >div:nth-child(9) {
      animation-delay: 3.2s;
    }
  }

  .ts {
    color: white;
    z-index: 3;
    position: fixed;
    top: 85%;
    left: 47%;
    display: none;
    cursor: pointer;
  }

  .ts:hover {
    display: block;
  }

  .gb {
    text-align: center;

    span {
      margin-left: 0.9375rem;
    }

    >div {
      display: inline-block;
    }
  }

  .iconbg {
    margin-top: 0.75rem;
    text-align: right;
    cursor: pointer;

    .iconbg {
      margin-top: 0.75rem;
      text-align: right;
      cursor: pointer;

      .imgicon {
        width: 3rem;
        height: 3rem;
        background: regb(0, 0, 0, 0.1);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .iconbg:hover {
      .imgicon {
        background: #3396fb;
      }

      .dianhua {
        display: block;
        background: regb(0, 0, 0, 0.5);

        span {
          font-size: 1.25rem;
          font-family: Impact;
          color: #ffffff;
          line-height: 1.5rem;
        }
      }
    }
  }

  .contOne {
    min-height: 43.75rem;
    background: url("@/assets/imgs/phone/1.png");
    position: relative;
    padding: 3.8125rem 0px 0px 0px;
    background-size: 100% 100%;
    box-sizing: border-box;

    .gyjy {
      width: 30%;
      margin: 0 auto;
      position: relative;

      .gytit {
        font-size: 1.5rem;
        font-family: MicrosoftYaHeiSemibold;
        color: #ffffff;
        line-height: 2rem;
        text-align: center;
      }

      .about {
        font-size: 1.5rem;
        font-family: impact;
        color: #ffffff;
        opacity: 0.3;
        line-height: 2.0625rem;
        text-align: center;
        margin-top: -1.25rem;
      }

      .top_xian {
        position: absolute;
        top: 112%;
        left: 30%;
        transform: translate(-9%, -9%);
        margin: auto;
        width: 3.3125rem;
        height: 0.25rem;
        background: #ffffff;
        border-radius: 0.0625rem;
      }
    }

    .gsjj {
      // margin-top: 140px;
      // display: flex;
      // overflow: hidden;
      z-index: 2;
      margin: 0 auto;
      // justify-content: center;
      margin-top: 30px;
      width: 100%;

      .jjleft {
        // width: 500px;
        // width: 600px;
        width: 100%;
        height: 17rem;
        position: relative;
        text-align: center;

        .xian_left {
          // width: 600px;
          // width: 90%;
          // height: 0.3125rem;
          // background: #3396fb;
          // border-radius: 0.0625rem;
          // margin: auto;
        }

        .jjleft_one {
          // width: 500px;
          height: 18.75rem;
          // background: #ffffff;
          opacity: 0.9;
          bottom: 0%;
          text-align: left;
          padding: 1.125rem;
          overflow: hidden;
          width: 90%;
          box-sizing: border-box;
          margin: 0 auto;

          p {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 25px;
            margin: 0;
          }

          .xian {
            width: 34px;
            height: 2px;
            background: #ffffff;
            border-radius: 0.0625rem;
            margin: auto;
            z-index: 33;
            position: absolute;
            top: 20%;
            left: 10%;
          }

          #htm {
            // width: 500px;
            overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 5;
            margin-top: 10%;
            // line-height: 25px;
            position: relative;

            ::v-deep p {
              // line-height: 25px;
              margin: 0;

              span {
                font-size: 13px !important;
                font-family: PingFang-SC-Medium, PingFang-SC !important;
                font-weight: 500 !important;
                line-height: 24px !important;
                color: #fff !important;
              }
            }
          }

          .texter {
            height: 8.125rem;
            position: relative;
            overflow: hidden;
            line-height: 24px;
            margin-top: 1.25rem;
          }

          .texter:after {
            content: " ... ";
            position: absolute;
            bottom: 5px;
            right: -1px;
            padding-left: 5px;
            color: #fff;
            // background: -webkit-linear-gradient(left, transparent, #fff 40%);
            // /* Safari 5.1-6.0 */
            // background: -o-linear-gradient(left, #fff 50%);
            // /* Opera 11.1-12.0 */
            // background: -moz-linear-gradient(left, #fff 50%);
            // /* Firefox 3.6-15 */
            // background: linear-gradient(to left, #fff, #fff);
            /* 标准语法 */
            /* 标准语法 */
          }

          .QHcolor2 {
            width: 88px;
            // background: #3396fb;
            position: relative;
            cursor: pointer;
            font-size: 13px;
            font-family: MicrosoftYaHei;
            color: #ffffff;
            height: 18px;
            margin: 20px auto;
            line-height: 18px;
            text-align: center;
          }

          .QHcolor2:after {
            content: " >";
            position: absolute;
            bottom: 1px;
            right: 0;
            font-size: 18px;
            color: #fff;
          }

          .QHcolor2:hover {
            background: #3396fb;
            transition: all 0.5s linear 0s;
          }
        }
      }

      // .bjj {
      //   // background: url("../assets/imgs/18bj.png") no-repeat;
      //   // background-size: 100% 100%;
      //   background: #ffffff;
      //   // width: 37.5rem;
      //   width: 90%;
      //   height: 88px;
      //   display: flex;
      //   justify-content: space-between;
      //   position: absolute;
      //   top: 74%;
      //   z-index: 1;

      //   .oneone {
      //     width: 9.375rem;
      //     height: 88px;
      //     text-align: center;
      //     cursor: pointer;

      //     #root {
      //       font-size: 24px;
      //       font-family: Impact;
      //       color: #3396FB;
      //       line-height: 29px;
      //       margin-top: 0.625rem;
      //     }

      //     p {
      //       font-size: 13px;
      //       font-family: PingFang-SC-Medium, PingFang-SC;
      //       font-weight: 500;
      //       color: #666666;
      //       line-height: 18px;
      //     }
      //   }

      //   .oneone2 {
      //     width: 9.375rem;
      //     height: 88px;
      //     text-align: center;
      //     background: #3396fb;
      //     z-index: 999;

      //     #root {
      //       font-size: 24px;
      //       font-family: Impact;
      //       color: #ffffff;
      //       line-height: 29px;
      //       margin-top: 0.625rem;
      //     }

      //     p {
      //       font-size: 13px;
      //       font-family: PingFang-SC-Medium, PingFang-SC;
      //       font-weight: 500;
      //       color: #ffffff;
      //       line-height: 18px;
      //     }
      //   }

      //   .oneone:hover {
      //     background: #3396fb;
      //     transition: all 0.5s linear 0s;

      //     #root {
      //       color: #ffffff;
      //       transition: all 0.5s linear 0s;
      //     }

      //     p {
      //       color: #ffffff;
      //       transition: all 0.5s linear 0s;
      //     }
      //   }
      // }

      // .bjj>.oneone:hover .oneone2 {
      //   background: #fff;

      //   #root {
      //     color: #3396fb;
      //   }

      //   p {
      //     color: #333333;
      //   }
      // }

      .aboutsmall {
        background-size: 100% 100%;
        width: 90%;
        margin: 5px auto;
        display: flex;

        p {
          font-size: 15px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 21px;
          margin-left: 30px;
        }

        #root2 {
          margin-left: 30px !important;

          span {
            font-size: 24px;
            font-family: Impact;
            color: #ffffff !important;
            line-height: 51px;
            align-items: center;
          }
        }
      }
    }
  }

  .contTwo {
    // min-width: 1920px;
    background: url("../assets/imgs/phone/aa@2x.png") no-repeat;
    background-size: 100% 100%;
    min-height: 43.75rem;
    position: relative;
    padding: 3.8125rem 0px 0px 0px;
    box-sizing: border-box;

    // margin-top: 24%;
    .gyjy {
      width: 60%;
      margin: 0 auto;
      position: relative;

      .gytit {
        font-size: 1.5rem;
        font-family: MicrosoftYaHeiSemibold;
        color: #ffffff;
        line-height: 2rem;
        text-align: center;
      }

      .about {
        font-size: 1.5rem;
        font-family: impact;
        color: #ffffff;
        opacity: 0.3;
        line-height: 2.0625rem;
        text-align: center;
        margin-top: -1.25rem;
      }

      .top_xian {
        position: absolute;
        top: 112%;
        left: 39%;
        transform: translate(-9%, -9%);
        margin: auto;
        width: 3.3125rem;
        height: 0.25rem;
        background: #ffffff;
        border-radius: 0.0625rem;
      }
    }

    .youshi {
      // width: 1100px;
      // margin: 0 auto;
      // padding: 50px;
      width: 100%;

      .wmdys {
        cursor: pointer;
        box-sizing: border-box;
        width: 88%;
        margin: 0.875rem auto 0;

        .ysbox {
          display: flex;
          box-sizing: border-box;
          justify-content: center;
          align-items: center;

          .imgss {
            width: 2.5rem;
            height: 2.1875rem;
            margin: 0 0.625rem;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .ysname {
            font-size: 1.125rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            text-align: center;
            width: 5.875rem;
          }

          .bianhao {
            font-size: 0.8125rem;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #ffffff;
            // line-height: 1.125rem;
            text-align: start;
            position: relative;
            border-left: 0.0625rem solid #ffffff;
            margin: 1.2rem 0.5625rem;
            padding: 0 1.2rem;
            box-sizing: border-box;
          }

          &:nth-child(1) {
            .bianhao {
              margin-right: -0.35rem;
            }
          }
        }
      }

      .ysbox:hover {
        background: rgba(255, 255, 255, 0.2);
        border-top: 0.25rem solid #3396fb;
        transition: all 0.5s linear 0s;
        transform: scale(1.2);
        transform-origin: center center;
      }
    }
  }

  .contThree {
    background: url("../assets/imgs/bg44.png") no-repeat;
    background-size: 100% 100%;
    min-height: 43.75rem;
    padding: 3.8125rem 0px 0px 0px;
    box-sizing: border-box;

    .gyjy {
      width: 60%;
      margin: 0 auto;
      position: relative;

      .gytit {
        font-size: 1.5rem;
        font-family: MicrosoftYaHeiSemibold;
        color: #ffffff;
        line-height: 2rem;
        text-align: center;
      }

      .about {
        font-size: 1.5rem;
        font-family: impact;
        color: #ffffff;
        opacity: 0.3;
        line-height: 2.0625rem;
        text-align: center;
        margin-top: -1.25rem;
        position: absolute;
        top: 100%;
        left: 12%;
        transform: translate(-9%, -9%);
        letter-spacing: 2px;
        width: 100%;
      }

      .top_xian {
        position: absolute;
        top: 135%;
        left: 43%;
        transform: translate(-9%, -9%);
        margin: auto;
        width: 3.3125rem;
        height: 0.25rem;
        background: #ffffff;
        border-radius: 0.0625rem;
      }
    }

    // ::v-deep .swiper-slide-next {
    //   transform: scale(.7);
    //   opacity: 0.5;
    //   pointer-events: none;
    // }

    .swiper-pagination {
      bottom: -10% !important;
    }

    ::v-deep.swiper-pagination-bullet-active {
      background: #ffffff !important;
    }

    ::v-deep .swiper-pagination-bullet {
      background: #ffffff !important;
      width: 0.625rem !important;
      height: 0.625rem !important;
      margin: 0 0.625rem;
    }

    // ::v-deep .swiper-slide-prev {
    //   transform: scale(.7);
    //   opacity: 0.5;
    //   pointer-events: none;
    // }

    .total {
      // width: 75rem;
      margin: 55px auto;
      display: flex;
      justify-content: center;
      position: relative;
      box-sizing: border-box;

      .case {
        width: 15.625rem;
        // width: 100%;
        height: 21.875rem;
        margin: 0 auto;
        position: relative;
        transition: 0.8s;
        cursor: pointer;

        .bboo {
          width: 3.125rem;
          height: 3.125rem;
          position: absolute;
          top: 30%;
          left: 48%;
          transform: translate(-40%, -40%);

          img {
            width: 100%;
            height: 100%;
          }
        }

        >.xian {
          width: 3.125rem;
          height: 0.125rem;
          background: #ffffff;
          border-radius: 0.0625rem;
          margin: auto;
          z-index: 0;
          position: absolute;
          top: 51%;
          left: 49%;
          transform: translate(-41%, -40%);
        }

        .totalbox {
          position: absolute;
          top: 69%;
          left: 48%;
          transform: translate(-41%, -40%);
          text-align: center;

          span {
            &:first-child {
              font-size: 1.125rem;
              font-family: MicrosoftYaHeiSemibold;
              color: #ffffff;
            }

            &:nth-child(3) {
              font-size: 0.6875rem;
              font-family: MicrosoftYaHei;
              color: #ffffff;
            }
          }
        }

        .block {
          padding: 5rem 1.875rem 0;
          background: #3396fb;
          height: 100%;
          transition: 0.8s;
          opacity: 0;
          text-align: center;
          color: #fff;
          box-sizing: border-box;
          display: none;

          span {
            &:first-child {
              font-size: 1.25rem;
              font-family: MicrosoftYaHeiSemibold;
              color: #ffffff;
            }

            &:nth-child(3) {
              font-size: 0.75rem;
              font-family: MicrosoftYaHei;
              color: #ffffff;
            }
          }

          .xian {
            width: 3.125rem;
            height: 0.125rem;
            background: #ffffff;
            border-radius: 0.0625rem;
            margin: auto;
            z-index: 33;
            display: block;
          }

          .sp {
            margin-top: 1.25rem;
            text-align: center;
            font-family: impact;
          }
        }
      }

      .case:hover {
        // transform: rotateY(180deg);
        transform: scale(1.05);
        transition: all 0.5s linear 0s;
        z-index: 999;

        .block {
          //   transform: scale(1.05);
          display: block;
        }

        .bboo {
          display: none;
        }

        >.xian {
          display: none;
        }

        .totalbox {
          display: none;
        }
      }

      .case:hover .block {
        opacity: 1;
      }

      .solon {
        img {
          height: 22.25rem;
          width: 9.25rem;
          transition: 0.8s;
          // cursor: pointer;
          margin: 0 0.625rem;
        }

        // .return {
        //   width: 23.9375rem;
        //   height: 29.5rem;
        //   background: rgba(242, 249, 255, 0.9);
        //   border-radius: 0.625rem;
        //   display: none;
        //   margin: 0 0.625rem;

        //   p {
        //     &:first-child {
        //       font-size: 1.625rem;
        //       font-family: Microsoft YaHei;
        //       font-weight: 600;
        //       color: #333333;
        //       text-align: center;
        //       margin: 0 auto;
        //       margin-top: 0.8125rem;
        //       padding: 1.875rem 0rem 0rem 0rem;
        //       box-sizing: border-box;
        //     }

        //     &:nth-child(2) {
        //       font-size: 0.75rem;
        //       font-family: HarmonyOS Sans SC;
        //       font-weight: 100;
        //       color: #333333;
        //       opacity: 0.5;
        //       margin: 0;
        //       text-align: center;
        //       margin-top: 0.625rem;
        //     }
        //   }

        //   .seamless-warp {
        //     height: 16.875rem;
        //     width: 22.5rem;
        //     margin: 1rem auto;
        //     overflow: hidden;
        //     list-style: none;

        //     .key {
        //       display: flex;
        //       flex-wrap: wrap;
        //       justify-content: center;

        //       -webkit-animation: 10s rowup linear infinite normal;
        //       animation: 10s rowup linear infinite normal;
        //       position: relative;

        //       .keyname {
        //         height: 2.25rem;
        //         width: 10.125rem;
        //         box-sizing: border-box;
        //         text-align: center;
        //         background: #CBE2F4;
        //         font-size: 0.875rem;
        //         font-family: Microsoft YaHei;
        //         font-weight: 400;
        //         color: #333333;
        //         margin: 0.4375rem;
        //         padding: 0rem 0.9375rem;
        //         line-height: 2.25rem;
        //         align-items: center;
        //         cursor: pointer;
        //       }

        //       .keyname:hover {
        //         background: #3396FB;
        //         color: #fff;
        //         transition: all 0.3s linear 0s;
        //       }
        //     }
        //   }

        // }
      }

      // @keyframes rowup {
      //   0% {
      //     -webkit-transform: translate3d(0, 0, 0);
      //     transform: translate3d(0, 0, 0);
      //   }

      //   100% {
      //     -webkit-transform: translate3d(0, -15rem, 0);
      //     transform: translate3d(0, -15rem, 0);
      //   }
      // }

      // .solon:hover {
      //   transform: scale(1.05);
      //   transition: all 0.5s linear 0s;
      //   z-index: 999;

      //   img {
      //     display: none;
      //   }

      //   .return {
      //     display: block;
      //   }
      // }
    }
  }

  .moreCont {
    width: 5.625rem;
    margin: 0.625rem auto;
    cursor: pointer;

    .QHcolor2 {
      width: 5.5rem;
      cursor: pointer;
      font-size: 0.875rem;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      height: 2rem;
      line-height: 2rem;
      // border: 0.0625rem solid #ffffff;
      text-align: center;
    }

    .QHcolor2::after {
      content: " >";
      position: absolute;
      bottom: 1px;
      right: 0;
      font-size: 18px;
      color: #ffffff;
    }

    .QHcolor2:hover {
      // background: #3396fb;
      transition: all 0.5s linear 0s;
      color: #3396fb;
      // border: 1px solid #3396fb;
    }
  }

  .viewTime {
    // margin-top: -120px;
    position: relative;
    z-index: 9;
    cursor: pointer;
  }

  .bbbox {
    // width: 1400px;
    width: 70%;
    margin: 0 auto;

    .contentItem {
      margin-top: 3.125rem;

      .twoCard {
        background: #fff;
        // max-width: 16.25rem;
        // max-height: 31.25rem;
        overflow: hidden;
        padding-bottom: 1.875rem;
        // box-shadow: 0rem 0rem 0.5rem 0.25rem rgba(0, 0, 0, 0.1);
        margin-bottom: 7.5rem;
        border-radius: 1.0625rem 1.0625rem 0.625rem 0.625rem;
        padding: 0.625rem;
        margin: auto;

        .images {
          // width: 230px;
          // height: 230px;
          width: 15vw;
          height: 15vw;
          border-radius: 50%;
          // padding: 10px;
          margin: 0 auto;
          background-size: auto 100% !important;
        }

        .titles {
          font-size: 1.375rem;
          font-family: MicrosoftYaHeiSemibold;
          color: #333333;
          padding: 0rem 0.125rem 0rem 0.125rem;
          position: relative;
          text-align: center;

          .xian {
            position: absolute;
            bottom: -0.625rem;
            left: 44%;
            margin: auto;
            width: 2rem;
            height: 0.125rem;
            background: #3396fb;
            border-radius: 0.0625rem;
          }
        }

        .trans {
          margin: 1.5625rem auto;
          // width: 13.75rem;
          // height: 9.375rem;
          padding: 0rem 1.25rem;
          line-height: 1.625rem;
        }
      }

      .twoPrev {
        position: absolute;
        left: -15%;
        top: 40%;
        cursor: pointer;
        z-index: 3;
      }

      .twoNext {
        position: absolute;
        right: -15%;
        top: 40%;
        cursor: pointer;
        z-index: 3;
      }
    }
  }

  .contFour {
    background: url("../assets/imgs/phone/ss@2x.png") no-repeat;
    background-size: 100% 100%;
    min-height: 43.75rem;
    padding: 3.8125rem 0px 0px 0px;
    box-sizing: border-box;

    .gyjy {
      width: 60%;
      margin: 0 auto;
      position: relative;

      .gytit {
        font-size: 1.5rem;
        font-family: MicrosoftYaHeiSemibold;
        color: #ffffff;
        line-height: 2rem;
        text-align: center;
      }

      .about {
        font-size: 1.5rem;
        font-family: impact;
        color: #ffffff;
        opacity: 0.3;
        line-height: 2.0625rem;
        text-align: center;
        margin-top: -1.25rem;
      }

      .top_xian {
        position: absolute;
        top: 131%;
        left: 39%;
        transform: translate(-9%, -9%);
        margin: auto;
        width: 3.3125rem;
        height: 0.25rem;
        background: #ffffff;
        border-radius: 0.0625rem;
      }
    }

    .swiper-container {
      // widows: 960px;
      margin: 0.625rem auto;

      // ::v-deep .swiper-slide-active {
      //   .con {
      //     opacity: 1 !important;
      //     transition: all 0.5s linear 0s !important;
      //   }
      // }

      // ::v-deep .swiper-slide-next {
      //   transform: scale(.7);
      // }

      // ::v-deep .swiper-slide-prev {
      //   transform: scale(.7);
      // }

      .oneSwiper {
        position: relative;
        display: flex;

        .item {
          .item_m {
            display: block;
            margin: 0 20px;
            position: relative;

            .con {
              position: absolute;
              left: 56px;
              right: 56px;
              top: 80px;
              opacity: 0;
              text-align: center;
              transition: all 300ms ease;
              // position: absolute;
              // left: 50%;
              // top: 85%;
              // transform: translate(-50%, -50%);
              // opacity: 0;
              transition: all 300ms ease;
              // background: #FFFFFF;
              // border-top: 4px solid #3396FB;
              // width: 50%;
              // height: 225px;
              // padding: 20px 40px;
              // box-sizing: border-box;

              .con_title {
                font-size: 22px;
                font-family: MicrosoftYaHeiSemibold;
                color: #ffffff;
                line-height: 30px;
              }

              .con_value {
                font-size: 14px;
                font-family: MicrosoftYaHei;
                color: #ffffff;
                line-height: 24px;
                margin-top: 30px;
                min-height: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
              }

              .con_lj {
                margin: 0 auto;
                margin-top: 40px;

                .moreCont2 {
                  width: 90px;
                  margin: 0 auto;
                  cursor: pointer;

                  .QHcolor3 {
                    width: 88px;
                    cursor: pointer;
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    background: #fff;
                    color: #333333;
                    height: 2rem;
                    line-height: 2rem;
                    text-align: center;
                  }

                  .QHcolor3:hover {
                    background: #3396fb;
                    color: #fff;
                    transition: all 0.5s linear 0s;
                  }
                }
              }
            }

            .imgsss {
              height: 436px;
              // height: 516px;
              // width: 900px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .twoPrev {
        position: absolute;
        left: 5rem;
        top: 40%;
        cursor: pointer;
        z-index: 3;
        width: 5.125rem;
        height: 3rem;
        display: none;

        img {
          width: 100%;
          height: 100%;
        }

        .twoPrev1 {
          display: block;
        }

        .twoPrev2 {
          display: none;
        }
      }

      // .twoPrev:hover {
      //   .twoPrev1 {
      //     display: none;
      //   }

      //   .twoPrev2 {
      //     display: block;
      //     transition: all 1s linear 0s;
      //   }
      // }

      .twoNext {
        position: absolute;
        right: 6.875rem;
        top: 40%;
        cursor: pointer;
        z-index: 3;
        width: 5.125rem;
        height: 3rem;
        display: none;

        img {
          width: 100%;
          height: 100%;
        }

        .twoNext1 {
          display: block;
        }

        .twoNext2 {
          display: none;
        }
      }

      // .twoNext:hover {
      //   .twoNext1 {
      //     display: none;
      //   }

      //   .twoNext2 {
      //     display: block;
      //     transition: all 1s linear 0s;
      //   }
      // }
    }

    .niua {
      width: 90%;
      margin: 0 auto;
      margin-top: 1.875rem;

      .niub {
        display: flex;
        margin-bottom: 1.875rem;
        width: 100%;
        background: #fff;
        cursor: pointer;

        .niuleft {
          // width: 10rem;
          // height: 7.5rem;
          width: 50%;

          img {
            width: 100%;
            height: 100%;
            // object-fit: contain;
          }
        }

        .niuright {
          box-sizing: border-box;
          padding: 0.625rem;
          width: 50%;

          p {
            &:first-child {
              font-size: 13px;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }

            &:nth-child(2) {
              font-size: 12px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #666666;
              line-height: 17px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
    }
  }

  .contFive {
    background: url("../assets/imgs/xw.png") no-repeat;
    background-size: 100% 100%;
    min-height: 43.75rem;
    padding: 3.8125rem 0px 0px 0px;
    box-sizing: border-box;

    .gyjy {
      width: 100%;
      margin: 0 auto;
      position: relative;
      padding-top: 0rem;

      .gytit {
        font-size: 1.5rem;
        font-family: MicrosoftYaHeiSemibold;
        color: #ffffff;
        line-height: 2rem;
        text-align: center;
      }

      .about {
        font-size: 1.5rem;
        font-family: impact;
        color: #ffffff;
        opacity: 0.3;
        line-height: 2.0625rem;
        text-align: center;
        margin-top: -1.25rem;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 9%;
        transform: translate(-9%, -9%);
        letter-spacing: 2px;
      }

      .top_xian {
        position: absolute;
        top: 135%;
        left: 43%;
        transform: translate(-9%, -9%);
        margin: auto;
        width: 3.3125rem;
        height: 0.25rem;
        background: #ffffff;
        border-radius: 0.0625rem;
      }
    }

    .news {
      margin-top: 1.4375rem;

      .newslist {
        width: 21.5625rem;
        height: 13.75rem;
        background: #ffffff;
        margin: 1.25rem auto;

        .date-img {
          height: 7.5rem;
          object-fit: cover;

          .imgs {
            width: 100%;
            height: 100%;
          }
        }

        .nn {
          box-sizing: border-box;
          padding: 0.9375rem;

          .titlediv {
            font-size: 0.9375rem;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #333333;

            .newstime2 {
              font-size: 0.75rem;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #666666;
              float: right;
            }
          }

          .con-txt {
            margin-top: 0.5rem;
            font-size: 0.8125rem;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #666666;
            line-height: 1.25rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }

    .phonenews {
      margin-top: 30px;

      .listone {
        width: 90%;
        margin: 0 auto;
        height: 273px;
        background: #fff;
        margin-bottom: 10px;

        .one_img {
          height: 145px;

          // width: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .one_nn {
          box-sizing: border-box;
          padding: 15px;

          p {
            &:first-child {
              margin: 0;
              font-size: 15px;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }

            &:nth-child(2) {
              font-size: 12px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #666666;
            }
          }

          .one_txt {
            font-size: 13px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #666666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }

      .phonelist {
        width: 90%;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        background: #fff;
        margin: 0 auto;

        .listtitle {
          width: 80%;
          box-sizing: border-box;
          padding: 9px 15px;

          p {
            &:first-child {
              margin: 0;
              font-size: 15px;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #333333;
            }

            &:nth-child(2) {
              font-size: 12px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #666666;
            }
          }
        }

        .listlo {
          width: 20%;
          line-height: 60px;
          text-align: center;
          // align-items: center;
        }
      }
    }
  }

  #sec7 {
    height: auto !important;

    ::v-deep .fp-tableCell {
      height: auto !important;
    }
  }

  .footerCon {
    position: relative;
    z-index: 99;
    width: 100%;
    background-color: #343433;
    display: flex;
    justify-content: center;
  }

  ::v-deep .el-carousel__button {
    width: 6.5rem;
  }

  ::v-deep .el-carousel__indicator--horizontal {
    display: inline-block;
    padding: 3.4375rem 0.625rem;
  }
}


.neimu {
  display: flex;
  margin: 0 auto;
  width: 1630px;

  .kuangleft {
    .kuang1 {
      background: url('@/assets/imgs/icon/kuang 1.png') no-repeat;
      background-size: 100% 100%;
      width: 350px;
      height: 455px;
      position: relative;
      margin-bottom: 15px;

      span {
        font-size: 24px;
        font-family: OPPOSans-Medium, OPPOSans;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
        position: absolute;
        top: 8%;
        left: 36%;
      }

      .nn1 {
        height: 300px;
        overflow: hidden;
        margin: 0 auto;
        margin-left: 25px;
        position: absolute;
        top: 25%;
        left: 22%;
      }

      ul {
        position: relative;

        li {
          font-size: 16px;
          font-family: OPPOSans-Medium, OPPOSans;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 25px;
        }
      }

    }

  }

  .kuang2 {
    background: url('@/assets/imgs/icon/kuang 2.png') no-repeat;
    background-size: 100% 100%;
    width: 350px;
    height: 230px;
    overflow: hidden;
    position: relative;

    span {
      font-size: 24px;
      font-family: OPPOSans-Medium, OPPOSans;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 28px;
      position: absolute;
      top: 8%;
      left: 36%;
    }

    .nn2 {
      height: 140px;
      overflow: hidden;
      margin: 0 auto;
      margin-left: 25px;
      position: absolute;
      top: 30%;
      left: 22%;

      ul {
        position: relative;

        li {
          font-size: 16px;
          font-family: OPPOSans-Medium, OPPOSans;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 25px;
        }
      }

    }
  }
}

#mapBox {
  width: 900px;
  height: 750px;
  background: transparent !important;
}

.kuang3 {
  background: url('@/assets/imgs/icon/kuang3.png') no-repeat;
  background-size: 100% 100%;
  width: 360px;
  height: 700px;
  overflow: hidden;
  position: relative;

  span {
    font-size: 24px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
    position: absolute;
    top: 4%;
    left: 36%;
  }

  .nn3 {
    height: 560px;
    overflow: hidden;
    margin: 0 auto;
    margin-left: 25px;
    position: absolute;
    top: 15%;
    left: 22%;

    ul {
      position: relative;

      li {
        font-size: 16px;
        font-family: OPPOSans-Medium, OPPOSans;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 25px;
      }
    }

  }
}

// .anli {
//   margin-top: 6%;

//   .shengfen {
//     font-size: 20px;
//     color: #FFFFFF;
//     text-align: center;
//   }

//   .mingzi {
//     width: 270px;
//     font-size: 14px;
//     color: #FFFFFF;
//     display: flex;
//     flex-wrap: wrap;
//     line-height: 30px;
//     margin-top: 20px;
//   }
// }
</style>
