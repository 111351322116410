import service from './request'; // 导入配置好的axios文件
import qs from "qs"

//list
export function getNotice(data = {}) {
  return service({
    url: '/cms/column/list',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json', //设置请求头请求格式form
    },
    params: data,
  });
}
//公告详情
export function getview(data = {}) {
  return service({
    url: '/cms/journalism/list',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json', //设置请求头请求格式form
    },
    params: data,
  });
}
//天气
export function fetweather(data = {}) {
  return service({
    url: '/weather/realTime?city='+data.city,
    method: 'post',
    headers: {
      'Content-Type': 'application/json', //设置请求头请求格式form
    },
    data,
  });
}
//详情
export function getXiangqing(data = {}) {
  return service({
    url: '/cms/journalism/'+data.id,
    method: 'post',
    headers: {
      'Content-Type': 'application/json', //设置请求头请求格式form
    },
    data,
  });
}
//商务合作
export function getBussing(data = {}) {
  return service({
    url: '/cms/cooperation',
    method: 'post',
    headers: {
      'Content-Type': 'application/json', //设置请求头请求格式form
    },
    data,
  });
}
