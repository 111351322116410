<template>
  <div id="ItemHeader" :class="[display != 'none' ? 'itno' : '', istopt ? 'topt' : '']" ref="theRef">
    <div class="logo"></div>
    <div :class="['nav', display != 'none' ? 'navno' : '']" @click="cknav"></div>
    <div :class="['content', display != 'none' ? 'contno' : '']" :style="{ 'display': display }">
      <div class="select">
        <div :class="['navs', ind1 == i ? 'act' : '']" @click="navClick(v, i, 0)" v-for="(v, i) in list" :key="i">
          <div class="navsnamess">
            <span>{{ v.navName }}</span>
            <span :class="ind1 == i ? 'xian activenav' : 'xian'"></span>
            <div class="arrow-right" v-if="i != 0"></div>
          </div>
          <div class="subnav" v-if="v.subnav">
            <div :class="['navsubnav', ind2 == index ? 'subnavActive' : '']" @click.stop="navClick(item, i, index)"
              v-for="(item, index) in v.subnav" :key="index">
              <span>{{ item.navName }}</span>
            </div>
          </div>
          <div class="beijing" v-if="v.subnav"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ind1: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      list: [
        {
          navName: "首页",
          path: "/"
        },
        {
          navName: "骥宇天哲",
          path: "/AboutView",
          tab: 0,
          subnav: [
            {
              navName: "公司简介",
              path: "/AboutView",
              tab: 0
            },
            {
              navName: "公司历程",
              path: "/AboutView",
              tab: 1
            },
            {
              navName: "公司荣誉",
              path: "/AboutView",
              tab: 2
            }
          ]
        },
        {
          navName: "新闻动态",
          path: "/NewsView",
          tab: 0
        },
        {
          navName: "解决方案",
          path: "/totalSolution",
          tab: 0,
          subnav: [
            {
              navName: "文旅数据驾驶舱",
              path: "/totalSolution",
              tab: 0
            },
            {
              navName: "文旅智慧服务体系",
              path: "/totalSolution",
              tab: 1
            },
            {
              navName: "文旅智慧监管体系",
              path: "/totalSolution",
              tab: 2
            },
            {
              navName: "文旅全域营销体系",
              path: "/totalSolution",
              tab: 3
            },
            {
              navName: "智慧博物馆文化馆",
              path: "/totalSolution",
              tab: 4
            },
            {
              navName: "数字乡村",
              path: "/totalSolution",
              tab: 5
            },
            {
              navName: "数字景区",
              path: "/totalSolution",
              tab: 6
            }
          ]
        },
        {
          navName: "客户案例",
          path: "/kehual",
          tab: 0,
          subnav: [
            {
              navName: "智慧文旅",
              path: "/kehual",
              tab: 0
            },
            {
              navName: "智慧景区",
              path: "/kehual",
              tab: 1
            },
            {
              navName: "数字乡村",
              path: "/kehual",
              tab: 2
            },
            // {
            //   navName: "分类叠加",
            //   path: "/kehual",
            //   tab: 4
            // }
          ]
        },
        {
          navName: "公司团队",
          path: "/TeamView",
          tab: 0
        },
        {
          navName: "商务合作",
          path: "/BusinessView",
          tab: 0
        }
      ],
      ind1: 7,
      ind2: 7,
      display: "none",
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      isMScreen: false,
      istopt: false
    };
  },
  methods: {
    cknav() {
      this.display == "none"
        ? (this.display = "block")
        : (this.display = "none");
    },
    navClick(v, i, index) {
      console.log(v, i, index);
      // this.ind1 = i;
      this.ind2 = index;
      console.log(this.ind1);
      console.log(this.ind2);
      this.$router.push({
        path: v.path,
        query: {
          tab: v.tab
        }
      });
      document.documentElement.scrollTop = 0;
    },
    handleScrollx() {
      //   console.log('滚动高度', window.pageYOffset)
      if (window.pageYOffset >= 118) {
        this.istopt = true;
      } else {
        this.istopt = false;
      }
    }
  },
  watch: {
    windowWidth(val) {
      let that = this;
      console.log("weach header实时屏幕宽度：", val);
      val <= 1200 ? (that.display = "none") : (that.display = "");
      val <= 1200 ? (that.isMScreen = true) : (that.isMScreen = false);
      console.log(that.display);
    }
  },
  mounted() {
    this.ind2 = this.$route.query.tab
    var that = this;
    window.fullWidth = document.documentElement.clientWidth;
    that.windowWidth = window.fullWidth; // 宽
    that.windowWidth <= 1200 ? that.display = "none" : that.display = "";
    that.windowWidth <= 1200 ? that.isMScreen = true : that.isMScreen = false;
    that.$store.commit("updateWidth", that.isMScreen);
    that.$store.commit("clientWidth", that.windowWidth);
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        that.windowWidth = window.fullWidth; // 宽
        // console.log(that.windowWidth);
        console.log("header实时屏幕宽度：", that.windowWidth);
        that.windowWidth <= 1200 ? that.display = "none" : that.display = "";
        that.windowWidth <= 1200 ? that.isMScreen = true : that.isMScreen = false;
        that.$store.commit("updateWidth", that.isMScreen);
        that.$store.commit("clientWidth", that.windowWidth);
        console.log(that.display);
      })();
    };
    // 滚动条的获取
    window.addEventListener("scroll", that.handleScrollx, true);
  }
};
</script>

<style scoped lang="scss">
/* 1200> max*/
@media screen and (min-width: 75rem) {
  #ItemHeader {
    min-width: 62.5%;
    height: 7.375rem;
    z-index: 3;
    color: #fff;
    font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
    position: fixed;
    top: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 62.5%;
    padding: 0 18.75%;

    &.topt {
      background: #3b73a2;
    }

    .logo {
      width: 8.5625rem;
      height: 1.5rem;
      // background: url("../assets/newimgs/logo@2x.png") no-repeat;
      background: url("../assets/logo5.png") no-repeat;
      // background-position: center center;
      // background-size: 100% 100%;
      background-size: cover;
      cursor: pointer;
    }

    .nav {
      display: none;
    }

    .content {
      width: 62%;

      .select {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .navs {
          font-size: 1rem;
          cursor: pointer;
          padding: 0rem 0.625rem 0rem 0.625rem;
          position: relative;
          text-align: center;
          height: 2.5rem;
          line-height: 2.5rem;

          .xian {
            position: absolute;
            bottom: 0rem;
            left: 0;
            right: 0;
            margin: auto;
            width: 0;
            height: 0.1875rem;
            background: #fff;
            border-radius: 0.0625rem;
            transition: all 0.5s;
          }

          .activenav {
            width: 100%;
          }
        }

        .navs:hover .xian {
          width: 100%;
        }

        .subnav {
          display: flex;
          position: absolute;
          left: -22.25rem;
          top: 2.5rem;
          margin: auto;
          // width: calc(100% - 1.25rem);
          // width: 100%;
          // background: rgba(0, 0, 0, 0.2);
          font-size: 0.875rem;
          // padding: 0 0.625rem 0.625rem;
          height: 0rem;
          overflow: hidden;
          transition: all 0.3s;
          opacity: 0;
          z-index: 999;
        }

        .beijing {
          position: fixed;
          left: 0rem;
          top: 4.8125rem;
          width: 100vw;
          background: rgba(0, 0, 0, 0.05);
          height: 2.5rem;
          display: none;
          z-index: 6;
        }

        .navsubnav {
          font-size: 0.875rem;
          // margin-top: 0.625rem;
          height: 2.1875rem;
          line-height: 2.1875rem;
          cursor: pointer;
        }

        .navsubnav:hover {
          color: #3396fb;
        }

        .subnavActive {
          color: #3396fb;
        }
      }

      .navs:hover .subnav {
        height: auto;
        opacity: 1;
        display: flex;
        width: 50.5rem;
        justify-content: space-evenly;
        transition: all 0.5s linear 0s;
      }

      .navs:hover .beijing {
        display: block;
        transition: all 0.5s linear 0s;
      }

      .subnav:hover {
        height: auto;
        opacity: 1;
      }
    }
  }
}

/* 0~75rem */
@media screen and (max-width: 75rem) {
  #ItemHeader {
    min-width: 90%;
    height: 5.375rem;
    z-index: 3;
    color: #fff;
    font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
    position: absolute;
    top: 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    z-index: 99999 !important;

    &.itno {
      background: #000;
    }

    .logo {
      width: 6.5625rem;
      height: 1.15rem;
      // background: url("../assets/imgs/phone/logo@2x.png") no-repeat;
      background: url("../assets/logo5.png") no-repeat;
      // background-position: center center;
      // background-size: 100% 100%;
      background-size: cover;
      cursor: pointer;
    }

    .nav {
      width: 1.375rem;
      height: 1.125rem;
      background: url("../assets/sj_slices/cd@2x.png") no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      cursor: pointer;

      &.navno {
        width: 2rem;
        height: 2rem;
        background: url("../assets/sj_slices/xx@2x.png") no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        animation: mymove 2s 1;
      }
    }

    @keyframes mymove {
      from {
        opacity: 0;
        transform: rotate(0deg);
      }

      to {
        opacity: 1;
        transform: rotate(180deg);
      }
    }

    .content {
      // display: none;
      position: absolute;
      top: 4.375rem;
      width: 90%;
      left: 0;
      padding: 0 5%;

      &.contno {
        background: #000;
      }

      .select {
        width: 100%;
        font-size: 1rem;

        .navs {
          cursor: pointer;
          padding: 0.875rem 0;

          .navsnamess {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .xian {
              display: none;
            }

            .arrow-right {
              width: 0.625rem;
              height: 0.625rem;
              background-color: transparent;
              border-color: #fff;
              border-style: solid;
              border-width: 0.125rem 0.125rem 0 0;
              transform: rotate(45deg);
            }
          }

          .subnav {
            margin: auto;
            width: 100%;
            background: rgba(0, 0, 0, 0.2);
            font-size: 1em;
            padding: 0 0rem 0.625rem;
            height: 0rem;
            overflow: hidden;
            transition: all 0.3s;
            opacity: 1;

            .navsubnav {
              font-size: 1em;
              margin-top: 0.625rem;

              &:hover {
                color: #3c5364;
              }
            }

            .subnavActive {
              color: #3c5364;
            }

            &:hover {
              height: auto;
              animation: myop 2s 1;
            }
          }

          &.act {
            color: #3396FB;
          }

          &:hover {
            .navsnamess {
              color: #3396FB;

              .arrow-right {
                transform: rotate(135deg);
              }
            }

            .subnav {
              height: auto;
              animation: myop 2s 1;
            }
          }
        }

        @keyframes myop {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>