<template>
	<div id="footerBox">
		<div v-show="!isMScreen">
			<div id="jytz">
				<div class="left">
					<!-- <img src="../assets/imgs/logos.png"> -->
					<img src="../assets/logo5.png">
				</div>
				<div class="right">
					<!-- <div class="rimgs rimgs1">
						<img class="img1" src="../assets/imgs/qq.png">
						<img class="img2" src="../assets/111.png">
					</div> -->
					<div class="rimgs rimgs2">
						<img class="img1" src="../assets/imgs/wx.png">
						<img class="img2" src="../assets/erweima.png">
					</div>
					<!-- <div class="rimgs rimgs3">
						<img class="img1" src="../assets/imgs/wb.png">
						<img class="img2" src="../assets/sj_slices/qrcode_for_gh_a23bab78f725_344.jpg">
					</div> -->
				</div>
			</div>
			<div id="center">
				<div class="centleft">
					<span v-for="(v, i) in dibuPath" :key="i" @click="navClick(v, i)">{{ v.navName }}</span>
				</div>
				<div class="centright">
					地址：浙江省杭州市萧山区文明路328号江宁大厦A幢9层 | Tel: 0571-83691210
				</div>
			</div>
			<!-- <div id="dibu">
				<div class="dibuleft">
					浙ICP备13037114号-2 * 2006-2022 TOUR-MA.COM ALL RIGHTS RESERVED.
				</div>
				<div class="diburight">
					BY：ZJJYWEB
				</div>
			</div> -->
		</div>
		<div class="move" v-show="isMScreen">
			<div class="moveitem">
				<img class="imggzh" src="../assets/sj_slices/qrcode_for_gh_a23bab78f725_344.jpg">
				<div>骥宇天哲微信公众号</div>
			</div>
			<div class="moveitem">
				<!-- <img class="imglogo" src="../assets/logo.png"> -->
				<img class="imglogo" src="../assets/logo5.png">
				<div style="margin-bottom: 2rem;">Tel: 400-800-5790</div>
				<div>地址：浙江省杭州市萧山区文明路328号江宁大厦A幢9层</div>
			</div>
			<div class="moveitem mod">
				<img class="img1" @click="callPhone('400-800-5790')" src="../assets/sj_slices/hhh@2x.png">
				<img class="img1" @click="map" src="../assets/sj_slices/ddd@2x.png">
			</div>
			<!-- <div class="moveitem noline">
				<div style="margin-bottom: 2rem;">浙ICP备13037114号-2 </div>
				<div>2006-2022 TOUR-MA.COM ALL RIGHTS RESERVED. </div>
			</div> -->
		</div>
		<el-backtop :visibility-height="500" :bottom="100">
			<div style="{
		        height: 100%;
		        width: 100%;
		        background-color: #f2f5f6;
		        box-shadow: 0 0 0.375rem rgba(0,0,0, .12);
		        text-align: center;
		        line-height: 2.5rem;
		        color: #bc9f71;
		      }">
				UP
			</div>
		</el-backtop>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dibuPath: [{
					navName: '骥宇天哲',
					path: '/AboutView',
					tab: 0,
				}, {
					navName: '解决方案',
					path: '/totalSolution',
					tab: 0,
				}, {
					navName: '客户案例',
					path: '/kehual',
					tab: 0,
				}, {
					navName: '公司团队',
					path: '/TeamView',
					tab: 0,
				}, {
					navName: '商务合作',
					path: '/BusinessView',
					tab: 0,
				},
				{
					navName: '新闻动态',
					path: '/NewsView',
					tab: 0,
				}],
				isMScreen: false,
				windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
			}
		},
		watch: {
			'$store.state.update.updateWidth'() {
				console.log('is:'+this.isMScreen);
				this.isMScreen = this.$store.state.update.updateWidth;
				
			}
		},
		mounted() {
			this.isMScreen = this.$store.state.update.updateWidth;
		},
		methods: {
			navClick(v, i) {
				this.$router.push({
					path: v.path,
					query: {
						tab: v.tab
					}
				});
				document.documentElement.scrollTop = 0;
			},
			callPhone(phoneNumber) {
				window.location.href = 'tel://' + phoneNumber
			},
			map() {
				window.open(
					`http://uri.amap.com/marker?position=120.271252,30.21271&name=浙江省杭州市萧山区文明路328号江宁大厦A幢9楼&coordinate=gaode&callnative=1`
					);
			}
		}
	}
</script>

<style scoped lang="scss">
	/* 1200> max*/
	@media screen and (min-width:75rem) {
		#footerBox {
			min-width: 75rem;
			margin: 0 18.75%;

			#jytz {
				font-size: 1em;
				font-family: MicrosoftYaHei;
				color: #FFFFFF;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 2.3125rem 0rem;
				border-bottom: 0.0625rem solid #999;
				box-sizing: border-box;

				.right {
					display: flex;

					.rimgs {
						margin-left: 3.125rem;
						position: relative;

						.img1 {
							width: 3.125rem;
							height: 3.125rem;
							cursor: pointer;
						}

						.img2 {
							position: absolute;
							width: 10rem;
							top: -10.625rem;
							display: none;
						}
					}

					.rimgs1 {
						.img2 {
							left: 0;
						}

						&:hover {
							.img2 {
								display: block;
							}
						}
					}

					.rimgs2 {
						.img2 {
							left: -3.4375rem;
						}

						&:hover {
							.img2 {
								display: block;
							}
						}
					}

					.rimgs3 {
						.img2 {
							right: 0;
						}

						&:hover {
							.img2 {
								display: block;
							}
						}
					}
				}
				.left{
					width: 137px;
					height: 24px;
					img{
						width: 100%;
						height: 100%;
					}
					
				}
			}

			#center {
				font-size: 1em;
				font-family: MicrosoftYaHei;
				color: #FFFFFF;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 1.875rem 0rem;
				// border-bottom: 0.0625rem solid #999;

				.centleft {
					display: flex;

					span {
						padding: 0 0.625rem;
						position: relative;
						cursor: pointer;

						&::after {
							position: absolute;
							left: 0;
							top: 0;
							bottom: 0;
							margin: auto;
							content: '';
							height: 80%;
							width: 0.0625rem;
							background-color: #fff;
						}
					}

					span:nth-of-type(1) {
						&::after {
							width: 0rem;
						}
					}

					span:hover {
						color: #3396FB;
					}
				}
			}

			#dibu {
				font-size: 1em;
				font-family: MicrosoftYaHei;
				color: #FFFFFF;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 1.875rem 0rem;

				.dibuleft {}
			}
		}
	}

	/* 0~75rem */
	@media screen and (max-width:75rem) {
		#footerBox {
			min-width: 90%;
			height: 100vh;

			// margin: 0 5%;

			.move {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				text-align: center;
				font-size: 1.5em;
				font-family: PingFang-SC-Medium, PingFang-SC;
				font-weight: 500;
				color: #FFFFFF;
				// padding-top: 4.0625rem;
				box-sizing: border-box;

				.moveitem {
					padding: 2rem 0;
					border-bottom: 0.1875rem solid #666;
					width: 100%;
					text-align: center;
					font-size: 0.8125rem;
					font-family: PingFang-SC-Medium, PingFang-SC;
					font-weight: 500;
					color: #FFFFFF;
						.imggzh {
							width: 6.25rem;
							height: 6.25rem;
							margin-bottom: 2rem;
						}

						.imglogo {
							width: 102px;
							height:19px;
							margin-bottom: 2rem;
						}

						.img1 {
							width: 2.75rem;
							height:2.75rem;
							margin: 0 2rem;
							cursor: pointer;
							cursor: pointer;
						}
					.moveitem {


					}

					.mod {}

					.noline {
						border-bottom: none;
					}
				}
			}
		}
	}
</style>
