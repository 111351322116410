import axios from 'axios';
import {
	Message
} from 'element-ui';
const service = axios.create({
	// baseURL: 'http://111.0.84.160:11190/search-biz',
	baseURL: 'https://m.zjjiyu.cn/jygw',
	// baseURL: 'http://localhost:8080/jygw',
	// 'http://111.0.84.160:11190/
	timeout: 50000,
	responseType: 'json',
});
//请求拦截器
service.interceptors.request.use(
	(config) => {
		return config;
	},
	(error) => {
		//出现异常抛出错误
		return Promise.reject(error);
	}
);

//响应拦截器
service.interceptors.response.use(
	(res) => {
		// console.log(res)
		//响应拦截
		if (res.status == 200) {
			// 对响应数据做些事
			//alert("提交成功")
			return Promise.resolve(res);
		} else {
			Message.error('请求失败，请联系管理员');
		}
		// return res
	},
	(error) => {
		//出现异常抛出错误
		Message.error(error.response.data.resp_msg);
		return Promise.reject(error);
	}
);
export default service; //导出封装好的axios对象
