let img = "image://" + require("@/assets/imgs/dituanniu/cd.png");
let img1 = "image://" + require("@/assets/imgs/dituanniu/hd.png");
let img2 = "image://" + require("@/assets/imgs/dituanniu/qd.png");
var allinfo = {
	dataList: [
		// type: 1 //1全域/2景区/3乡村/4文保
		{
			name: '浙江文旅',
			value: [120.152575, 30.266619, [40, 50], //前两个是经纬度   后两个是标记点渲染的大小和偏移量
				[0, 0],
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false,
		},
		{
			name: '焦作文旅',
			value: [113.245674, 35.221554, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'河南',
			symbol: img, // 单个数据标记的图形
			// symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)] ,// 单个数据标记的大小
			isActive: false
		},
		{
			name: '枣庄文旅',
			value: [117.323759, 34.810858, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'山东',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '芜湖文旅',
			value: [118.433065, 31.352614, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'安徽',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '松原文旅',
			value: [124.825321, 45.14191, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'吉林',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '桐乡文旅',
			value: [120.565127, 30.630375, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '高平文旅',
			value: [112.923798, 35.798775, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'山西',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '乐清文旅',
			value: [120.986297, 28.112519, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '临海文旅',
			value: [121.144625, 28.859042, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '正定文旅',
			value: [114.577412, 38.152565, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'河北',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '洞头文旅',
			value: [121.157406, 27.836412, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '鹿城文旅',
			value: [
				120.706477,
				28.001085,
				[40, 50], //前两个是经纬度   后两个是标记点渲染的大小和偏移量
				[0, 0],
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false,
		},
		{
			name: '椒江文旅',
			value: [121.442859, 28.673334, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '三门文旅',
			value: [121.39561, 29.104888, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '仙居文旅',
			value: [120.730026, 28.845778, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '瑞安文旅',
			value: [120.655245, 27.778967, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '萧山文旅',
			value: [120.264263, 30.184119, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '南浔文旅',
			value: [120.418244, 30.850835, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '龙湾文旅',
			value: [120.812333, 27.933261, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '吴兴文旅',
			value: [120.185608, 30.857184, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '新昌文旅',
			value: [120.903918, 29.500525, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '文成文旅',
			value: [120.090929, 27.786856, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '缙云文旅',
			value: [120.091685, 28.659294, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '永嘉楠溪江旅发',
			value: [120.744171, 28.257881, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '泰顺文旅',
			value: [119.717643, 27.556578, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '宁国文旅',
			value: [118.983085, 30.634032, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'安徽',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '浦口文旅',
			value: [118.633594, 32.065475, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'江苏',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '诸暨文旅',
			value: [120.251829, 29.71506, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '苍南文旅',
			value: [120.425957, 27.518636, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '建德文旅',
			value: [119.281195, 29.474964, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '开化文旅',
			value: [118.415756, 29.136729, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '江山文旅',
			value: [118.627228, 28.73796, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '湄洲岛文旅',
			value: [119.128483, 25.063753, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'福建',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '平阳文旅',
			value: [120.565161, 27.662394, [40, 50],
				[0, 0]
			],
			type: 1,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '温宿文旅',
			value: [80.243273, 41.272995, [40, 50],
				[0, 0]
			],
			type: 1,
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			sf:'新疆维吾尔自治区',
			isActive: false
		},
		// type: 2 //1全域/2景区/3乡村/4文保
		//浙江:楠溪江、雁荡山、天台山、神仙居、百岛洞头、刘伯温故里、半边山景区、廊氡旅游度假区、湘湖旅游度假区、台州府城、东湖、龙湾潭、氧吧小镇、玉苍山、九峰山、仙都景区、石桅岩景区、猪猪星球、牛山坞田园综合体、南浦溪小镇、仙居国家公园、乌岩岭等等
		{
			name: '乌岩岭',
			value: [119.662291, 27.711021, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '仙居国家公园',
			value: [120.614859, 28.741508, [40, 50],
				[0, 0]
			],
			type: 2,
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '猪猪星球',
			value: [120.938434, 30.577633, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '石桅岩景区',
			value: [120.874906, 28.384325, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '仙都景区',
			value: [120.132563, 28.685408, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '九峰山',
			value: [120.838668, 28.811612, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '玉苍山',
			value: [120.296855, 27.517159, [40, 50],
				[0, 0]
			],
			type: 2,
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '楠溪江',
			value: [120.900433, 28.386678, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '天台山',
			value: [121.03576, 29.157417, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '神仙居',
			value: [120.750659, 28.873747, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '百岛洞头',
			value: [121.136549, 27.830719, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '东湖',
			value: [121.029654, 30.698274, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '半边山景区',
			value: [121.985385, 29.27378, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '廊氡旅游度假区',
			value: [119.999761, 27.469894, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '湘湖旅游度假区',
			value: [120.209336, 30.133265, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '雁荡山',
			value: [121.033716, 28.373656, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '龙湾潭',
			value: [120.882295, 28.338996, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '台州府城',
			value: [121.117232, 28.845475, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '刘伯温故里',
			value: [119.988101, 27.884162, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '南浦溪',
			value: [119.939774, 27.586605, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'浙江',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		//福建:湄洲岛
		{
			name: '湄洲岛',
			value: [119.132693, 25.086997, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'福建',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		//重庆:阿依河
		{
			name: '阿依河',
			value: [108.158288, 29.171593, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'重庆',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		//江苏:珍珠泉、栖霞山
		{
			name: '珍珠泉',
			value: [118.962689, 32.155368, [40, 50],
				[0, 0]
			],
			type: 2,
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			sf:'江苏',
			isActive: false
		},
		{
			name: '栖霞山',
			value: [118.969792, 32.158175, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'江苏',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		//山东:潍坊欢乐海沙滩、台儿庄古城、风筝冲浪基地、欢乐海旅游度假区
		{
			name: '欢乐海旅游度假区',
			value: [119.207405, 37.21517, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'山东',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '风筝冲浪基地',
			value: [119.212702, 37.221478, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'山东',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '潍坊欢乐海沙滩',
			value: [119.210459, 37.219755, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'山东',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '山东台儿庄古城',
			value: [117.749556, 34.563811, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'山东',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		//吉林:长白山
		{
			name: '长白山',
			value: [128.119637, 42.423617, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'吉林',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		//河北:木兰围场、塞罕坝
		{
			name: '塞罕坝',
			value: [117.391147, 42.3623, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'河北',
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '木兰围场',
			value: [117.494816, 42.320385, [40, 50],
				[0, 0]
			],
			type: 2,
			sf:'河北',
			isActive: false
		},
		//河南:云台山青龙峡
		{
			name: '云台山青龙峡',
			value: [113.241402, 35.413502, [40, 50],
				[0, 0]
			],
			type: 2,
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			sf:'河南',
			isActive: false
		},
		//新疆:阿克苏温宿
		{
			name: '阿克苏温宿',
			value: [80.063796, 41.430617, [40, 50],
				[0, 0]
			],
			type: 2,
			symbol: img1, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			sf:'新疆',
			isActive: false
		},
		// type: 3 智慧城市、数字乡村、数字小镇、数字街区、智慧文保
		{
			name: '江南仙境游',
			value: [119.536049, 29.885661, [40, 50],
				[0, 0]
			],
			type: 3,
			symbol: img2, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			sf:'浙江',
			isActive: false
		},
		{
			name: '松阳旅游古村落',
			value: [115.904152, 40.206678, [40, 50],
				[0, 0]
			],
			type: 3,
			sf:'浙江',
			symbol: img2, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '牛山坞田园综合体',
			value: [116.17116, 40.486659, [40, 50],
				[0, 0]
			],
			type: 3,
			sf:'浙江',
			symbol: img2, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '南浦溪小镇',
			value: [119.940029, 27.619777, [40, 50],
				[0, 0]
			],
			type: 3,
			sf:'浙江',
			symbol: img2, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '氧吧小镇',
			value: [120.614992, 28.743803, [40, 50],
				[0, 0]
			],
			type: 3,
			sf:'浙江',
			symbol: img2, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '数字正定',
			value: [114.57249, 38.130856, [40, 50],
				[0, 0]
			],
			type: 3,
			sf:'浙江',
			symbol: img2, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '顺溪文保',
			value: [120.19861, 27.562404, [40, 50],
				[0, 0]
			],
			type: 3,
			sf:'浙江',
			symbol: img2, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
		{
			name: '数字忠义街',
			value: [120.631351, 27.782868, [40, 50],
				[0, 0]
			],
			type: 3,
			sf:'浙江',
			symbol: img2, // 单个数据标记的图形
			//symbolSize: [utils.fontSize(0.45), utils.fontSize(0.5)], // 单个数据标记的大小
			isActive: false
		},
	]
}
export default allinfo;