import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map';
import "animate.css"
import VueAnimateNumber from 'vue-animate-number'
import vueSeamless from 'vue-seamless-scroll'
// import animated from 'animate.css'
// import { WOW } from 'wowjs'
// 全屏滚动 vue-fullpage.js
// import * as echarts from 'echarts'
// Vue.prototype.$echarts = echarts;
import echartsGL from 'echarts-gl'
Vue.prototype.$echartsGL = echartsGL
import 'fullpage.js/vendors/scrolloverflow';
import VueFullpage from 'vue-fullpage.js'
Vue.use(BaiduMap, {
  ak: 'eA2lWlWhYEwetAnkqcq31Ku8nC7Peytm'
})
// Vue.prototype.$wow = new WOW({
//   boxClass: 'wow', // 需要执行动画的元素的 class
//   animateClass: 'animated', //animation.css 动画的 class
//   offset: 0, // 距离可视区域多少开始执行动画
//   mobile: true, // 是否在移动设备上执行动画
//   live: true, // 异步加载的内容是否有效
//   }),
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueFullpage);
Vue.use(VueAnimateNumber);
Vue.use(scroll)
// Vue.use(animated);
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
