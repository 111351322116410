import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/AboutView',
    name: 'AboutView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/totalSolution',
    name: 'totalSolution',
    component: (resolve) => require(['../views/totalSolution'], resolve),
  },
  {
    path: '/kehual',
    name: 'kehual',
    component: (resolve) => require(['../views/kehual'], resolve),
  },
  {
    path: '/khalxq',
    name: 'khalxq',
    component: (resolve) => require(['../views/khal/khalxq'], resolve),
  },
  {
    path: '/ceshi',
    name: 'ceshi',
    component: (resolve) => require(['../views/ceshi'], resolve),
  },
    {path: '/TeamView',
    name: 'TeamView',
    component: () => import(/* webpackChunkName: "about" */ '../views/TeamView.vue')
  },
  {
    path: '/CaseView',
    name: 'CaseView',
    component: () => import(/* webpackChunkName: "about" */ '../views/CaseView.vue')
  },
  {
    path: '/BusinessView',
    name: 'BusinessView',
    component: () => import(/* webpackChunkName: "about" */ '../views/BusinessView.vue')
  },
  {
    path: '/abvw',
    name: 'abvw',
    component: () => import(/* webpackChunkName: "about" */ '../components/index.vue')
  },
  {
    path: '/NewsView',
    name: 'NewsView',
    component: (resolve) => require(['../views/NewsView'], resolve),
  },
  {
    path: '/newsxq',
    name: 'newsxq',
    component: (resolve) => require(['../views/newsxq'], resolve),
  },
  {
    path: '/demo',
    name: 'demo',
    component: (resolve) => require(['../views/demo'], resolve),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
const VueRouterPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (to) {

	return VueRouterPush.call(this, to).catch(err => err)

}
		
export default router
